import React, { Component } from 'react'
import {Layout, message,  Table, Modal, Image, Checkbox, Input, Button} from "antd";


import moment from "moment";
import {TYPE_NAME} from "../../components/Const";
import {
    passHandleCheck, queryCheckInfoDetail,
    queryCustomerExamineAndApprove,
    queryHiddenDanger, rejectHandleCheck, updateResults,
} from "../../services/meter";
import FormsTwo from "../../components/common/forms/FormsTwo";
const { TextArea } = Input;
// 隐患审批
export default class approvalOfHiddenDangers extends Component {
    state={
        pushId:null,
        modeTitle:'新增来源',
        alarmTypeName:null,     // 	报警类型名称
        modalVisible_1:false,   // 弹窗
        modalVisible_2:false,   // 弹窗—2查看隐患图片
        visible_1:false,        // 弹窗-审批

        // 主表格
        tableParamenter_1_value:[
            {title: '客户编号', dataIndex: 'customerId', key: 'customerId'},
            {title: '客户名称', dataIndex: 'customerName', key: 'customerName'},
            {title: '客户类型', dataIndex: 'type', key: 'type',},
            {title: '分类', dataIndex: 'classification', key: 'classification',},
            {title: '联系人', dataIndex: 'legalPerson', key: 'legalPerson'},
            {title: '联系电话', dataIndex: 'legalPersonPhone', key: 'legalPersonPhone',},
            {title: '详细地址', dataIndex: 'address', key: 'address',},
            {title: '最近安检时间', dataIndex: 'securityCheckedTime', key: 'securityCheckedTime',
                render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>}},
            {title: '最近应安检日期', dataIndex: 'securityCheckRequiredTime', key: 'securityCheckRequiredTime',
                render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>}},
            {title: '安全隐患', dataIndex: 'status', key: 'status'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (_, record) =>
                    <div>
                        <div className={'tableLink'} title={'审批'}>
                            <u onClick={()=>{
                                if(record.status==='已处理') return false;// 判断状态，已处理停止操作
                                this.queryCheckInfoDetail(record);// 复选框和文本框数据
                                this.modalQueryHiddenDanger(record);
                                this.setState({visible_1:true,selectRowData_1:record})
                            }}
                               style={{color:(record.status==='已处理')?'#ccc':null}}
                            >审批</u>
                        </div>
                    </div>
            },
        ],
        // 只记录打那个数据
        tableParamenter_1_selectOne:this.props?.location?.state?.selectParams?.selectOne?this.props?.location?.state?.selectParams?.selectOne:[], // 单选框
        tableParamenter_1_selectData:this.props?.location?.state?.selectParams?.selectData?this.props?.location?.state?.selectParams?.selectData:[],// 单选数据
        tableParamenter_1_ALLSelectData:[],// 记录当前展开的数据
        // 表格主体数据
        tableParamenter_1_data:[],
        tableParamenter_1_param:{
            total:0,
            page:1,
            size:10,
        },
        tableParamenter_1_loading:false,
        // 子表格
        tableParamenter_2_value:[
            {title: '燃气泄露', dataIndex: 'name', key: 'name'},
            {title: '级别', dataIndex: 'level', key: 'level'},
            {title: '未处理', dataIndex: 'state', key: 'state'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (_, record) =>
                    <div>
                        <div>
                            <div className={'tableLink'} title={'查看'}>
                                <u onClick={()=>{
                                    console.log(record);
                                    this.setState({fileList:record?.photos||[],modalVisible_2:true})
                                }}>查看</u>
                            </div>
                        </div>
                    </div>
            },
        ],


        tableParamenter_2_data:[],
        tableParamenter_2_param:{
            page:this.props?.location?.state?.selectParams?.page?this.props?.location?.state?.selectParams?.page:1,
            size:this.props?.location?.state?.selectParams?.size?this.props?.location?.state?.selectParams?.size:10,
            total:0
        },
        fileList: [],   // 设备照片
        measures:[],    // 弹窗-类型选择
        remark:null,    // 弹窗-备注
        reject_remark:null,// 弹窗-驳回备注

        // 弹框表格
        table_columns:[
            {title: '隐患名称',dataIndex: 'name', key: 'name',align:'center',width:'100px'},
            {title: '分级',dataIndex: 'level', key: 'level',align:'center',width:'100px'},
            {title: '状态',dataIndex: 'state', key: 'state',align:'center',width:'100px'},
            {title: '处理',dataIndex: 'edit', key: 'edit',align:'center',width:'100px',
                render: (text, rowData,index) => {
                    return<>
                        <div  title={'未处理'} style={{cursor: 'pointer'}}
                              onClick={()=>{
                                  console.log('查看');
                                  console.log(rowData);
                                  // this.setState({visible_2:true,selectRowData_2:rowData});
                                  this.setState({fileList:rowData?.photos||[],modalVisible_2:true})
                              }}
                        >
                            <u style={{color:'red'}}>查看</u>
                        </div>
                    </>
                }
            },
        ],
        table_data:[], //弹框表格数据
        topFormItem: {
            modal: true,
            number: 2,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'customerName',
                    str: '客户名称',
                    rules: TYPE_NAME.INPUT,
                },
                {
                    variableName: 'detectorNo',
                    str: '状态',
                    rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'未处理',value:'未处理'},
                        {key:'待审批',value:'待审批'},
                        {key:'审批驳回',value:'审批驳回'},
                    ]
                },

            ]
        },
        searchGetData:{},
    };
    // 初始化
    componentWillMount() {
        this.queryCustomerExamineAndApprove(); // 查询灶具隐患
    }

    // 查询灶具隐患
    queryCustomerExamineAndApprove=(data)=>{
        console.log(data);
        // 获取选取地址
        let menusStr = localStorage.getItem('menus');
        let menus =
            ((menusStr != null || menusStr !== "null") && typeof menusStr == 'string')
                ?
                JSON.parse(localStorage.getItem('menus'))
                : [];
        var param={
            areaIds:typeof menus === 'object' ? menus : [menus],
            customerId:data?.customerId??null,
            status:data?.detectorNo??null,
            page:1,
            size:10
        };
        queryCustomerExamineAndApprove(param).then(
            response=>{
                var tableParamenter = this.state.tableParamenter_1_data;
                var parameter = this.state.tableParamenter_1_param;
                tableParamenter=[];
                if(response.data.data){
                    parameter.total=response.data.pageInfo.total;
                    parameter.page=response.data.pageInfo.pageLine;
                    tableParamenter=response.data.data;
                }
                this.setState({
                    tableParamenter_1_data:tableParamenter,
                    tableParamenter_1_loading:false,
                    tableParamenter_1_param:parameter
                });
            }
        )

    };

    // 弹窗表格数据
    modalQueryHiddenDanger=(record)=>{
        var params =  new URLSearchParams();
        params.append('classification',record?.classification);
        params.append('recordId',record?.recordId);
        queryHiddenDanger(params).then(
            reponse=>{
                if(reponse?.data?.data){
                    var data = reponse?.data?.data;
                    var datas=[];
                    data.map(one=>{
                        if(one?.photos){
                            datas.splice(0,0,one,)
                        }else{
                            datas.push(one)
                        }
                    });
                    this.setState({table_data:datas});
                }
            });
    };

    //子框数据
    queryHiddenDanger=(recordId,classification)=>{
        if(!recordId)return false;
        var params =  new URLSearchParams();
        params.append('classification',classification);
        params.append('recordId',recordId);
        queryHiddenDanger(params).then(
            reponse=>{
                if(reponse?.data?.data){
                    var tableParamenter1 = [...this.state.tableParamenter_1_data];
                    for(var i=0;i<tableParamenter1.length;i++){
                        if(tableParamenter1[i].recordId === recordId){
                            tableParamenter1[i].dataz={};
                            tableParamenter1[i].dataz = reponse.data;
                        }
                    }
                    this.setState({tableParamenter_1_data:tableParamenter1});
                }
            });
    };

    // 弹窗数据
    queryCheckInfoDetail=(record)=>{
        var params =  new URLSearchParams();
        params.append('classification',record?.classification);
        params.append('recordId',record?.recordId);
        queryCheckInfoDetail(params).then(
            response=>{
                if(response?.data?.data){
                    this.setState({
                        measures:response.data.data?.measures??[],          // 多选框
                        remark:response.data.data?.remark??null,            // 描述
                    })
                }
            });
    };

    // 通过审批
    passHandleCheck=()=>{
        var param={
            flowId:this.state.selectRowData_1.flowId,
            operator: JSON.parse(sessionStorage.getItem("user")).orgId, // 操作人id
            operatorName: JSON.parse(sessionStorage.getItem("user")).orgName,// 操作人名
        };
        passHandleCheck(param).then(
            response=>{
                if(response?.data?.data){
                    message.info('审批通过');
                    this.queryCustomerExamineAndApprove(); // 查询灶具隐患
                    this.setState({visible_1:false});
                }else{
                    message.info('审批失败')
                }
            }
        )
    };
    // 驳回
    rejectHandleCheck=()=>{
        var param={
            classification:this.state.selectRowData_1.classification,
            flowId:this.state.selectRowData_1.flowId,
            operator: JSON.parse(sessionStorage.getItem("user")).orgId,         // 操作人id
            operatorName: JSON.parse(sessionStorage.getItem("user")).orgName,   // 操作人名
            recordId: this.state.selectRowData_1.recordId,   // 操作人名
            remark:this.state.reject_remark??null,
        };
        rejectHandleCheck(param).then(
            response=>{
                if(response?.data?.data){
                    message.info('驳回成功');
                    this.updateResults(); // 更新评估
                    this.queryCustomerExamineAndApprove(); // 查询灶具隐患
                    this.setState({visible_1:false});
                }else{
                    message.info('驳回失败')
                }
            }
        )

    };

    updateResults=()=>{
        // 更新评估结果
        updateResults({customerId:this.state.selectRowData_1.customerId}).then(
            response=>{
                if(response?.data?.data){

                }
            }
        )
    };


    // 子框分页参数
    getPaginationRight=(page,size)=>{
        var params = this.state.tableParamenter_2_param;
        params.page=page;
        params.size=size;
        this.setState({tableParamenter_2_param:params});
        // this.getConfigsByAlarmSourceId();
    };

    render() {
        return (
            <Layout  className={'item_body_number_back tables'}>
                <div id={'deviceConfigure'} style={{background:'#fff'}}>
                    {/*<div className={'topSearchBox'}>*/}
                    {/*    <h3>隐患信息</h3>*/}
                    {/*</div>*/}

                    <div style={{width:'100%',padding:"10px 0 0 10px"}}>
                        <div style={{width:'80%'}}>
                            <div style={{width:'45%',float:'left'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem}
                                    data={this.state.searchGetData}
                                />
                            </div>
                            <div style={{float:'left'}}>
                                <Button type="primary" onClick={async () => {
                                    var data = await this.forms.onFinishNotReset();
                                    console.log(data);
                                    this.queryCustomerExamineAndApprove(data)
                                }}>查询
                                </Button>
                            </div>
                        </div>
                        <div style={{float:'left'}}>
                            <Button  onClick={()=>{this.forms.onModalClose()}}>清空</Button>
                        </div>
                    </div>

                    <div style={{width:'100%',padding:"0 5px 0 5px"}}>
                        <div style={{background:'#fff'}}>
                            <Table
                                bordered={true}
                                rowKey={'flowId'}
                                columns={this.state.tableParamenter_1_value}
                                dataSource={this.state.tableParamenter_1_data}
                                loading={this.state.tableParamenter_1_loading}
                                pagination = {{
                                    showSizeChanger:true,
                                    showQuickJumper: true,
                                    onChange: (page,size)=>{
                                        var param = this.state.tableParamenter_1_param;
                                        // this.queryCustomerExamineAndApprove(); // 查询灶具隐患
                                    },       // 获得翻页数值
                                    total:this.state.tableParamenter_1_param.total,
                                    pageSize:10,
                                    showTotal: (count = this.state.tableParamenter_1_param.total)=>{
                                        return '共'+count+'条数据'
                                    },
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['10']
                                }}



                                // 展开点击事件
                                onExpand={(juder,data)=>{
                                    var tableParamenter_1_ALLSelectData = this.state.tableParamenter_1_ALLSelectData;// 记录当前所有展开的数据
                                    // 是否展开
                                    if(juder){
                                        tableParamenter_1_ALLSelectData.push(data);
                                        this.setState({tableParamenter_1_ALLSelectData:tableParamenter_1_ALLSelectData});
                                        this.queryHiddenDanger(data?.recordId,data?.classification); // 刷新子表数据,将数据封装起来-新
                                    }else{
                                        // 查询并删除
                                        for(var i=0;i<tableParamenter_1_ALLSelectData.length;i++){
                                            if(data.id===tableParamenter_1_ALLSelectData[i].id){
                                                tableParamenter_1_ALLSelectData.splice(i,1)
                                            }
                                        }
                                        this.setState({tableParamenter_1_ALLSelectData:tableParamenter_1_ALLSelectData});
                                    }
                                }}

                                // 父子框
                                expandable={{
                                    // expandedRowKeys:this.state.tableParamenter_1_selectOne,
                                    onExpandedRowsChange:(rows)=>{},
                                    // 展开样式
                                    expandedRowRender:(record)=>{
                                        // console.log('展开样式');
                                        // console.log(record);
                                        return<>
                                            <Table
                                                columns={this.state.tableParamenter_2_value}
                                                dataSource={record?.dataz?.data}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                                    total:record?.dataz?.data.length,
                                                    pageSize:10,
                                                    showTotal: (count = record?.dataz?.data.length)=>{
                                                        return '共'+count+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['10']
                                                }}
                                            />
                                        </>
                                    },
                                    rowExpandable: (record) => record.id !== 'Not Expandable',
                                }}
                            />
                        </div>
                    </div>

{/*图片---------------------------------------------------------------------------------------------------------------*/}
                    <Modal
                        title={'查看'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible_2}
                        // okText={this.state.modalFormItemText}
                        // 点击确定
                        onOk={()=>{this.setState({modalVisible_2:false})}}
                        // 点击取消
                        onCancel={()=>{this.setState({modalVisible_2:false})}}
                        width={600}
                        centered
                    >
                        {this.state?.fileList.map(one=>{return<><Image width={105} src={one.path}/></>})}
                    </Modal>
{/*审批---------------------------------------------------------------------------------------------------------------*/}
                    <Modal
                        title={"处理"}
                        maskClosable={false}
                        style={{ top: 0 }}
                        visible={this.state.visible_1}
                        okText="确定"
                        onOk={() => {}}           	// 点击确定
                        onCancel={() => this.setState({visible_1:false})}	// 点击取消
                        footer={[
                            <Button key="back" onClick={() => this.setState({visible_1:false})}>返回</Button>,
                            <Button type="primary" onClick={() => this.rejectHandleCheck(false)}>驳回</Button>,
                            <Button type="primary" onClick={() => this.passHandleCheck(false)}>通过</Button>
                        ]}
                        width={900}
                        centered
                    >
                        <div style={{width:'100%'}}>
                            <div style={{width:'100%',height:'30px'}}>
                                <Checkbox.Group value={this.state.measures}>
                                    <Checkbox value="线器正刚" disabled={true}>限期正刚</Checkbox>
                                    <Checkbox value="停业整顿" disabled={true}>停业整顿</Checkbox>
                                </Checkbox.Group>
                            </div>
                            <div style={{width:'100%',height:'100px'}}>
                                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} disabled={true} value={this.state.remark}/>
                            </div>
                            <div style={{width:'100%',height:'100%',overflowY: 'auto'}}>
                                <Table
                                    bordered
                                    pagination={false}
                                    scroll={{ y: 240 }}
                                    columns={this.state.table_columns}
                                    dataSource={this.state.table_data}
                                />
                            </div>
                            <div style={{width:'100%',height:'100px',paddingTop:'10px',}}>
                                <span style={{color:'red'}}>
                                    驳回:
                                </span>
                                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} value={this.state.reject_remark}
                                          onChange={(text)=>{
                                              this.setState({reject_remark:text.target.value});
                                          }}
                                />
                            </div>
                        </div>
                    </Modal>

                </div>
            </Layout>
        )
    }
}
