import React, { Component } from 'react'
import { Layout, Select, Form ,Modal,message} from 'antd'
import TableComponents from '../../components/common/tables/TableComponents'
import {getImportInfo,getDataId} from '../../services/alarm'

const { Option } = Select;
const FormItem = Form.Item;

export default class MeterImportRecord extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			// manageName: "报表查询",
            rowSelection: false,
			width: '83.7vw',
            title: '表具导入',
			// exportUrl: "/web/reportExportCtrl/exportDataDifference",
			importUrl: "/gateway/meterimport/meterImport/importInfo",
			// 表头：变量和变量解释
			header: {
                steelNum: '表钢号',
				simCard: 'SIM卡号',
				modelName: '表具型号',
                areaAddress: '区域地址',
				manufactureData:'生产日期',
				informationRecord: '导入信息'
			},

		},
		// 下拉框
		listDatas: [],
		// 类型选择
		modelDatas: [],
		manufactureValue: null,
		meterModelValue: null
	}

	importTable = () => {
		let menus = JSON.parse(localStorage.getItem('menus')) ?? []
        menus = Array.isArray(menus) ? menus[0] : menus
		let areaName=localStorage.getItem('areaName')
		// areaName = Array.isArray(areaName) ? areaName[0] : areaName
		console.log(menus,areaName)
		let param = {
			menus:menus
		  }

		if (areaName == null) return message.error('未选择供应商,请选择供应商')
		Modal.confirm({
		title: `是否绑定到${localStorage.getItem('areaName')}?`,
		onOk: () => {
		getDataId(param).then(
			response => {
			  this.setState({
				loading: false,
				refreshTable: false,
			  });
			}
		  ).catch(
			() => {
			  this.setState({
				loading: false,
				refreshTable: false,
			  });

			}
		  )
		}
    })
	}

	render() {

		// let {listDatas, modelDatas} = this.state

        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getImportInfo}
					importTable={this.importTable}
					// getConditionsValue={this.getConditionsValue}
					clearCondition={this.clearCondition}
					ref={e => this.table = e}
				>
				</TableComponents>
            </Layout>
        )
    }
}
