import React, { Component } from 'react'
import {Layout, message, Tag, Table, Popconfirm, Modal} from "antd";
import TableComponents1 from "../../../components/common/tables/TableComponentsSub1";

import {NavLink} from "react-router-dom";
import {PlusSquareOutlined} from '@ant-design/icons';
import {
    getConfigsByAlarmSourceId,
    getPageAlarmSourceQuery, updateStatez, updateState, addAlarmSource, updateAlarmSource, delAlarmSource
} from "../../../services/qrCode";
import moment from "moment";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {funTraceInfo} from '../../../services/common'
import {TYPE_NAME} from "../../../components/Const";
import FormsTwo from "../../../components/common/forms/FormsTwo";
// 报警消息配置信息
export default class deviceConfigure extends Component {
    state={
        pushId:null,
        modeTitle:'新增来源',
        alarmTypeName:null,     // 	报警类型名称
        modalVisible_1:false,   // 弹窗
        modalFormItem: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'systemName', str: '系统名称', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'alarmTypeId', str: '报警类型名称', rules: TYPE_NAME.REQ_SELECT,
                    data:[
                        {key:1,value:'外部电源断电报警'},
                        {key:2,value:'通信掉线报警'},
                        {key:3,value:'零流量报警'},
                        {key:4,value:'低流量报警'},
                        {key:5,value:'超流量报警'},
                        {key:6,value:'可燃气体报警器浓度报警'},
                    ],
                    onChange:((a,b)=>{
                        this.setState({alarmTypeName:b.children})
                    })
                },
                {variableName: 'level', str: '报警等级', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:1,value:'高'},
                        {key:2,value:'中'},
                        {key:3,value:'低'},
                        ]},
                {variableName: 'pushContent', str: '推送内容', rules: TYPE_NAME.TEXT_AREA,},
                {variableName: 'state', str: '状态 ', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:1,value:'启动'},
                        {key:2,value:'禁用'}
                    ]
                },

            ],
        },
        searchGetData:{
            level:2,
            state:1
        },

        tableParamenter_1_value:[
            {title: '信息来源', dataIndex: 'systemName', key: 'systemName'},
            {title: '报警类型', dataIndex: 'alarmTypeName', key: 'alarmTypeName'},
            {title: '报警等级', dataIndex: 'level', key: 'level',
                render: (text, record) => <div>{text===1?'高':text===2?'中':text===3?'低':'未知'}</div>},
            {title: '报警消息', dataIndex: 'pushContent', key: 'pushContent'},
            {title: '更新时间', dataIndex: 'updateTime', key: 'updateTime',
                render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>
            }},
            {title: '创建时间', dataIndex: 'createTime', key: 'createTime',
                render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>}},
            {title: '创建人', dataIndex: 'createUserName', key: 'createUserName'},
            {title: '信息来源', dataIndex: 'systemName', key: 'systemName'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (_, record) =>
                    <div>
                        {record.state===1?
                            <>
                                <div  className={'tableLink'} title={'启动'}>
                                    <u style={{color:'#ccc'}}>启动</u>
                                </div>
                                <div  className={'tableLink'} title={'禁用'}>
                                    <Popconfirm title="是否禁用该设备?"
                                                onConfirm={()=>{this.updateStateF(record.id,2)}}
                                                okText="是" cancelText="否">
                                        <u>禁用</u>
                                    </Popconfirm>
                                </div>
                            </>
                            :
                            <>
                                <div  className={'tableLink'} title={'启动'}>
                                    <Popconfirm title="是否启动该设备?"
                                                onConfirm={()=>{this.updateStateF(record.id,1)}}
                                                okText="是" cancelText="否">
                                        <u>启动</u>
                                    </Popconfirm>
                                </div>
                                <div  className={'tableLink'} title={'禁用'}>
                                    <u  style={{color:'#ccc'}}>禁用</u>
                                </div>
                            </>
                        }
                        <div  className={'tableLink'} title={'修改'}
                              onClick={async ()=>{
                                  this.setState({pushId:record.id});
                                  this.modalData(true,record,'修改来源')
                              }}
                        >
                            <u>修改</u>
                        </div>
                        <div  className={'tableLink'} title={'删除'}>
                            <Popconfirm title="是否删除?"
                                        onConfirm={()=>{this.delAlarmSource(record.id,1)}}
                                        okText="是" cancelText="否">
                                <u>删除</u>
                            </Popconfirm>

                        </div>
                    </div>},
        ],
        // 只记录打那个数据
        tableParamenter_1_selectOne:this.props?.location?.state?.selectParams?.selectOne?this.props?.location?.state?.selectParams?.selectOne:[], // 单选框
        tableParamenter_1_selectData:this.props?.location?.state?.selectParams?.selectData?this.props?.location?.state?.selectParams?.selectData:[],// 单选数据
        tableParamenter_1_ALLSelectData:[],// 记录当前展开的数据
        // 表格主体数据
        tableParamenter_1_data:[],
        tableParamenter_1_param:{
            page:1,
            size:10,
        },
        tableParamenter_1_loading:false,
        tableParamenter_2_value:[
            {title: '配置Id', dataIndex: 'id', key: 'id'},
            {title: '配置名', dataIndex: 'configName', key: 'configName'},
            {title: '推送方式', dataIndex: 'noticeTypeName', key: 'noticeTypeName'},// 推送方式、短信、2、电话、3、邮件、4、app、5、系统页面
            // {title: '状态', dataIndex: 'state', key: 'state'},
            {title: '推送拨打电话', dataIndex: 'phones', key: 'phones'},
            {title: '短信推送目标', dataIndex: 'sms', key: 'sms'},
            {title: '邮箱推送目标', dataIndex: 'emails', key: 'emails'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (_, record) =>
                    <div>
                        <div className={'tableLink'} title={'请选择设备'}
                             onClick={()=>{
                                 var rowData=this.state.tableParamenter_1_selectData;
                                 if(rowData.length===0){
                                     return message.info("请选择设备")
                                 }
                                 var selectParams = {
                                     page:this.state.tableParamenter_2_param.page,
                                     size:this.state.tableParamenter_2_param.size,
                                     selectOne:this.state.tableParamenter_1_selectOne,
                                     selectData:this.state.tableParamenter_1_selectData,
                                 };
                                 console.log(rowData);
                                 this.props.history.push({
                                     pathname: '/deviceConfigure/details/newConfigurePage',
                                     state:{
                                         rowData: rowData[0],       // 所选数据的父层数据
                                         headerChild:record,        // 所选数据
                                         selectParams:selectParams  // 记录当前表格操作数据
                                     }
                                 })
                             }}
                        >
                            <u>{'修改'}</u>
                        </div>
                        {record.state===1?
                            <>
                                <div  className={'tableLink'} title={'启动'}>
                                    <u style={{color:'#ccc'}}>启动</u>
                                </div>
                                <div  className={'tableLink'} title={'禁用'}>
                                    <Popconfirm title="是否禁用该设备?"
                                                onConfirm={()=>{this.updateState(record.id,2)}}
                                                okText="是" cancelText="否">
                                        <u>禁用</u>
                                    </Popconfirm>
                                </div>
                            </>
                            :
                            <>
                                <div  className={'tableLink'} title={'启动'}>
                                    <Popconfirm title="是否启动该设备?"
                                                onConfirm={()=>{this.updateState(record.id,1)}}
                                                okText="是" cancelText="否">
                                        <u>启动</u>
                                    </Popconfirm>
                                </div>
                                <div  className={'tableLink'} title={'禁用'}>
                                    <u  style={{color:'#ccc'}}>禁用</u>
                                </div>
                            </>
                        }

                    </div>
            },
        ],

        tableParamenter_2_data:[],
        tableParamenter_2_param:{
            page:this.props?.location?.state?.selectParams?.page?this.props?.location?.state?.selectParams?.page:1,
            size:this.props?.location?.state?.selectParams?.size?this.props?.location?.state?.selectParams?.size:10,
            total:0
        }
    };
    // 初始化
    componentWillMount() {
        this.getPageAlarmSourceQuery();

    }
    getPageAlarmSourceQuery=()=>{
        console.log('刷新');

        this.setState({tableParamenter_1_loading:true});
        getPageAlarmSourceQuery(this.state.tableParamenter_1_param).then(
            response=>{
                if(response.data.data){
                    var tableParamenter = this.state.tableParamenter_1_data;
                    var tableParamenter_1_ALLSelectData = this.state.tableParamenter_1_ALLSelectData;
                    tableParamenter=response.data.data;

                    tableParamenter_1_ALLSelectData.map(one=>{
                        if(tableParamenter.find(two=>{if(one.id===two.id) return true;})){
                            console.log(one);
                            this.getConfigsByAlarmSourceId(one);// 刷新子表数据,将数据封装起来
                        }

                    });



                    this.setState({tableParamenter_1_data:tableParamenter})
                }else{
                    message.info('查询数据失败')
                }
                this.setState({tableParamenter_1_loading:false});
            }

        )
    };

    getConfigsByAlarmSourceId=(data)=>{

        getConfigsByAlarmSourceId({alarmSourceId:data.id,type:0}).then(
            response=>{
                if(response.data.data){
                    var tableParamenter1 = [...this.state.tableParamenter_1_data];
                    for(var i=0;i<tableParamenter1.length;i++){
                           if(tableParamenter1[i].id === data.id){
                               tableParamenter1[i].dataz={};
                               tableParamenter1[i].dataz = response.data;
                           }
                    }
                    this.setState({tableParamenter_1_data:tableParamenter1});
                }
            }
        )
    };
    // setTableValue = (value,text,data,) => {
    //     if(text === 'null') return '-';
    //     switch (value) {
    //         case 'updateTime':
    //             return text?moment(text).format("yyyy-MM-DD 00:00:00"):'-';
    //         case 'createTime':
    //             return text?moment(text).format("yyyy-MM-DD 00:00:00"):'-';
    //         case 'state':
    //             text = text*1;
    //             return text*1 === 1 ? <span>启动</span> : text*1 === 2 ? <span>停用</span> : <span>其他</span>;
    //         case 'edit':
    //             return<>
    //                 <div className={'tableLink'} title={'修改'}
    //                      onClick={()=>{
    //                          var rowData=this.state.tableParamenter_1_selectData;
    //                          if(rowData.length===0){
    //                              return message.info("请选择设备")
    //                          }
    //                          var selectParams = {
    //                              page:this.state.tableParamenter_2_param.page,
    //                              size:this.state.tableParamenter_2_param.size,
    //                              selectOne:this.state.tableParamenter_1_selectOne,
    //                              selectData:this.state.tableParamenter_1_selectData,
    //                          };
    //                          console.log(rowData);
    //                          console.log(selectParams)
    //                          console.log(data)
    //                          // this.props.history.push({
    //                          //     pathname: '/deviceConfigure/details/newConfigurePage',
    //                          //     state:{
    //                          //         rowData: rowData[0],
    //                          //         headerChild:data,
    //                          //         selectParams:selectParams
    //                          //     }
    //                          //
    //                          // })
    //                      }}
    //                 >
    //                     <u>{'修改'}</u>
    //                 </div>
    //             </>;
    //         default:
    //             return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
    //     }
    // };

    // 创建接口
    createDevice = () => {
        var rowData=this.state.tableParamenter_1_selectData;
        if(rowData.length===0){
            return message.info("请选择设备")
        }
        var selectParams = {
            page:this.state.tableParamenter_2_param.page,
            size:this.state.tableParamenter_2_param.size,
            selectOne:this.state.tableParamenter_1_selectOne,
            selectData:this.state.tableParamenter_1_selectData,
        };
        this.props.history.push({
            pathname: '/deviceConfigure/details/newConfigurePage',
            state:{
                rowData: rowData[0],
                selectParams:selectParams
            }
        })
    };

    onChangeLeftTable=(_,b)=>{
        console.log(b)
    };
    getPaginationRight=(page,size)=>{
        var params = this.state.tableParamenter_2_param;
        params.page=page;
        params.size=size;
        this.setState({tableParamenter_2_param:params});
        // this.getConfigsByAlarmSourceId();
    };

    // 修改父类状态
    updateStateF=(id,state)=>{
        console.log(id,state);
        updateState({configId:id,state:state}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    this.getPageAlarmSourceQuery();
                    this.getConfigsByAlarmSourceId({id:id});
                }else{
                    message.info('修改失败')
                }
            }
        )

    };

    // 修改状态
    updateState=(id,state)=>{
        console.log(id,state);
        updateStatez({configId:id,state:state}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    this.getConfigsByAlarmSourceId({id:id});
                }else{
                    message.info('修改失败')
                }
            }
        )

    };

    addAlarmSource= async () =>{
        var params = await this.forms.onFinishNotReset();
        if(!params){return false}
        // params.alarmTypeName=this.state.alarmTypeName;
        params.createUserId=JSON.parse(sessionStorage.getItem("user")).id;
        params.createUserName=JSON.parse(sessionStorage.getItem("user")).username;
        console.log(params);
        addAlarmSource(params).then(
            response=>{
                if(response?.data){
                    if(response?.data.responseInfo.code===200){
                        message.info('新增成功');
                        this.setState({modalVisible_1:false,searchGetData:{}});
                        this.getPageAlarmSourceQuery();
                    }else if(response?.data.responseInfo.code===500){
                        message.info('新增失败')
                    }else{
                        message.info('异常问题，请重新操作')
                    }
                }
            }
        ).catch(e=>{
            message.error("异常问题，请重新尝试")
        })

    };

    updateAlarmSource= async () =>{
        var params = await this.forms.onFinishNotReset();
        console.log(params);
        if(!params) return false;
        if(this.state?.pushId){
            params.id=this.state.pushId;
        }
        updateAlarmSource(params).then(
            response=>{
                if(response?.data.responseInfo.code===200){
                    message.info('修改成功');
                    this.setState({modalVisible_1:false,searchGetData:{}});
                    this.getPageAlarmSourceQuery();
                }else if(response?.data.responseInfo.code===500){
                    message.info('修改失败')
                }else{
                    message.info('异常问题，请重新操作')
                }
            }
        ).catch(e=>{message.error("异常问题，请重新尝试")})
    };
    delAlarmSource=(id)=>{
        var param = new URLSearchParams();
        param.append("id",id);
        delAlarmSource(param).then(response=>{
            if(response?.data){
                if(response?.data.responseInfo.code===200){
                    message.info('删除成功');
                    this.setState({modalVisible_1:false,searchGetData:{}});
                    this.getPageAlarmSourceQuery();
                }else if(response?.data.responseInfo.code===500){
                    message.info('删除失败')
                }else{
                    message.info('异常问题，请重新操作')
                }
            }
        });
    };

    //添加设备窗口显示
    modalData = async (devVisible,data,title)=>{
        await this.setState({modalVisible_1:devVisible,searchGetData:data,modeTitle:title});
        await this.forms.onModalClose()
    };
    render() {
        return (
            <Layout  className={'item_body_number_back tables'}>
                <div id={'deviceConfigure'}>
                    <div className={'topSearchBox'}>
                        <h3>报警消息配置信息</h3>
                    </div>
                    <div className={'topBtnBox'}
                         // style={{"pointer-events": this.state.pointer_1}}
                    >
                        <div style={{float:'left'}}>
                            <span className={'addLink'}  onClick={()=>{this.modalData(true,{},'新增来源')}}><PlusSquareOutlined />创建来源</span>
                        </div>
                        <div style={{float:'left'}}>
                            <span className={'addLink'}  onClick={this.createDevice}><PlusSquareOutlined />创建新配置</span>
                        </div>
                        <div style={{float:'right'}}>
                            <span className={'addLink'}  onClick={this.getPageAlarmSourceQuery}>刷新</span>
                        </div>
                    </div>
                    <div>
                        <div style={{padding:'10px 0 0 0',background:'#fff'}}>
                            <Table
                                rowKey={'id'}
                                columns={this.state.tableParamenter_1_value}
                                dataSource={this.state.tableParamenter_1_data}
                                loading={this.state.tableParamenter_1_loading}
                                // 单选框事件
                                rowSelection={{
                                    selectedRowKeys:this.state.tableParamenter_1_selectOne,// 选中值
                                    onChange:(a,b)=>{
                                        this.setState({tableParamenter_1_selectOne:a,tableParamenter_1_selectData:b});
                                    },
                                    type:"radio",
                                    //"checkbox" "radio"
                                }}
                                // 展开点击事件
                                onExpand={(juder,data)=>{

                                    var tableParamenter_1_ALLSelectData = this.state.tableParamenter_1_ALLSelectData;// 记录当前所有展开的数据
                                    if(juder){
                                        //单选框

                                        tableParamenter_1_ALLSelectData.push(data);
                                        var selectOne = this.state.tableParamenter_1_selectOne;
                                        selectOne=[];// 初始化
                                        selectOne.push(data.id);// 添加展开的数据的id
                                        // 选择值
                                        var selectData = this.state.tableParamenter_1_selectData;
                                        selectData=[];
                                        selectData.push(data);// 保存展开的数据


                                        // 当前只记录最近一次点击
                                        this.setState({tableParamenter_1_selectOne:selectOne,tableParamenter_1_selectData:selectData,tableParamenter_1_ALLSelectData:tableParamenter_1_ALLSelectData});
                                        this.getConfigsByAlarmSourceId(data);// 刷新子表数据,将数据封装起来
                                    }else{
                                        // 查询并删除
                                        for(var i=0;i<tableParamenter_1_ALLSelectData.length;i++){
                                            if(data.id===tableParamenter_1_ALLSelectData[i].id){
                                                console.log('找到了');
                                                tableParamenter_1_ALLSelectData.splice(i,1)
                                            }
                                        }
                                        this.setState({tableParamenter_1_ALLSelectData:tableParamenter_1_ALLSelectData});
                                    }
                                }}

                                // 父子框
                                expandable={{
                                    // expandedRowKeys:this.state.tableParamenter_1_selectOne,
                                    onExpandedRowsChange:(rows)=>{},
                                    // 展开样式
                                    expandedRowRender:(record)=>{
                                        return<>
                                            <Table
                                                columns={this.state.tableParamenter_2_value}
                                                dataSource={record?.dataz?.data}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                                    total:record?.dataz?.data.length,
                                                    pageSize:10,
                                                    showTotal: (count = record?.dataz?.data.length)=>{
                                                        return '共'+count+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['10']
                                                }}
                                            />
                                            </>
                                    },
                                    rowExpandable: (record) => record.id !== 'Not Expandable',
                                }}
                            />
                        </div>
                    </div>
                    <Modal
                        title={this.state.modeTitle}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible_1}
                        // okText={this.state.modalFormItemText}
                        // 点击确定
                        onOk={() =>{
                            if(this.state.modeTitle==='新增来源'){
                                this.addAlarmSource();
                            }else{
                                this.updateAlarmSource();
                            }
                        }}
                        // 点击取消
                        onCancel={()=>{this.setState({modalVisible_1:false})}}
                        width={600}
                        centered
                    >
                        <FormsTwo
                            ref={e => this.forms = e}
                            formParamenter={this.state.modalFormItem}
                            data={this.state.searchGetData}
                        />
                    </Modal>

                </div>
            </Layout>
        )
    }
}
