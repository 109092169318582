import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Popconfirm, Button, Tag, Input} from 'antd'
import { NavLink } from "react-router-dom"

import TableComponents from '../../../components/common/tables/TableComponents'
import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
import {pageLikeMpName,openMp,getMetersByAreaIds,replaceMeter} from '../../../services/meter'
import {deleteMp, disarmMeter, getPageCustomerName, migrateMeteringPointCustomers} from "../../../services/customer";
import moment from "moment";

export default class PointManagement extends Component {

    state = {

		// 条件查询栏
		topFormItem_1: {
			modal: true,
			number: 4,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{variableName: 'contractNum', str: '合同号', rules: TYPE_NAME.INPUT,disabled: true,},
				{variableName: 'id', str: '计量点编码', rules: TYPE_NAME.INPUT,disabled: true,},
				{variableName: 'mpAreaName', str: '区域', rules: TYPE_NAME.INPUT,disabled: true,},
			]
		},
		topFormItem_1_data:[],
		topFormItem_2: {
			modal: true,
			number: 4,
			labelCol: 9,
			layout: "horizontal",
			parameter: [
				{variableName: 'id', str: '客户编号', rules: TYPE_NAME.INPUT,},
				{variableName: 'name', str: '客户名称', rules: TYPE_NAME.INPUT,},
				{variableName: 'businessCooperationNum', str: '业务合作号', rules: TYPE_NAME.INPUT,},
			]
		},
		topFormItem_2_data:[],
		// 表格参数
		tableParamenter_1: {
			manageName: "客户关系管理系统",
			// 表格行是否可选
			rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				id: '客户编号',
				businessCooperationNum: '业务合作号',
				name: '客户名称',
				type: '客户类型'
			},
			params: {
				page: 1,
				size: 5,
			}
		},

		// 表格参数
		tableParamenter: {
			manageName: "计量点信息管理",
			// 表格行是否可选
            rowSelection: true,
			//下拉框长度
			selectWidth: '150px',
			// 表头：变量和变量解释
			header: {
				id: '计量点代码',
				installNumber: '安装号',
				purpose: '用气用途',
				properties: '用气性质',
				gasEquipment: '用气设备',
				name: '计量点名称',
				customerId: '客户号',
				contractNumber: '合同号',
				steelNum: '表钢号',
				state:'状态',
				edit:'操作'
			},
			// 弹出框
			headers: {
				province: '省',
				city: '市',
				district: '区',
				street: '街道',
				buildNum: '楼栋号',
				unitNum: '单元号',
				storeyNum: '楼层号',
				doorplateNum: '门牌号',
				address: '详细地址',
				installSite: '安装位置',
				state: '计量点状态',
				environment: '用气环境',
				isInstall: '是否装表',
				lastSettlementTime: '最后结算时间',
				// installTime: '安装时间',
				fixedWay: '固定方式',
			},
			params: {
				page:1,
				size:13,
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					// {
					// 	type: TYPE_NAME.NAME,
					// 	variableName: 'steelNum',
					// 	str: '表钢号'
					// },
					{
						type: TYPE_NAME.NAME,
						variableName: 'name',
						str: '计量点名称'
					},
					{
						type: TYPE_NAME.NAME,
						variableName: 'steelNum',
						str: '表钢号'
					},
					{
						type: 'select',
						variableName: 'sort',
						str: '排序',
						data: [
							{key: "", value: '升序'},
							{key: 2, value: '降序'},
						]
					},

				]
			},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 弹出表格参数
		tablePopParamenter: {
			manageName: "计量点信息管理",
			// 表格行是否可选
            rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				steelNum: '表钢号',
				installSite: '安装位置',
				communication: '表具通讯号',
				modelName: '表具型号',
				type: '表具类型',
				state: '表具状态',
			},
			params: {
				page:1,
				size:9,
				isAreaIds:0
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: TYPE_NAME.INPUT,
						variableName: 'steelNum',
						str: '表钢号'
					},
				]
			},
			// 是否绑定左侧区域
			bindArea: false
		},
		topFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'oldMeterSheetNum',
					str: '旧表钢号',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'oldMeterDalData',
					str: '旧表示数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'newMeterDalData',
					str: '新表示数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'offTime',
					str: '拆表时间',
					rules: TYPE_NAME.DATE_PICKER2,
				},
				{
					variableName: 'onTime',
					str: '装表时间',
					rules: TYPE_NAME.DATE_PICKER2,
				},
			]
		},
		changeData:{

		},
		openMpVisible:false, //开通
		openChangeMeter:false, //换表
		mpId:'',
		changeMeterId:'',
		changeMeterCode:'',
		ControlButtons_1:false,// 控制按钮-换表

		openMpVisible_1:false, //拆表窗口开关器-2
		openMpVisible_2:false, //迁移窗口开关器-3

		selectOneData:{},
		InputData:'',
	};

		// 表格数据渲染
		setTableValue = (value,text,rowData) => {
			switch (value) {
				case 'edit':
					text = rowData.state;
					return <>
					<div className={'tableLink'} title={'开通计量点'} onClick={() => this.openMp(true,rowData.id)}>
						<u>{'开通计量点'}</u>
					</div>
						<div className={'tableLink'} title={'迁移计量点'}
							 onClick={async () => {
							 	await this.setState({openMpVisible_2:true,topFormItem_1_data:rowData})
								 this.forms1.setFieldsValue('id',rowData.id??null,)
						}}>
							<u>{'迁移计量点'}</u>
						</div>
						<div className={'tableLink'} title={'开通计量点'} onClick={() =>
						{
							if(!rowData?.meterId){
								return message.info("当前计量点没有表具，无法操作");
							}
							this.setState({openMpVisible_1:true,selectOneData:rowData})
						}
						}>
							<u>{'拆表'}</u>
						</div>
						{this.state.ControlButtons_1?
							<div className={'tableLink'} title={'换表'} onClick={() => this.openChangeMeter(true,rowData.id,rowData.steelNum)}>
								<u>{'换表'}</u>
							</div>
							:null}
						<div className={'tableLink'} title={'删除'} >
							<Popconfirm
								title="是否确定删除"
								description="是否确定删除?"
								onConfirm={()=>{
									if(rowData?.steelNum){
										return message.info("当前计量点有表具，无法操作");
									}
									this.deteleMp(rowData?.id);
								}}
								// onCancel={cancel}
								okText="是"
								cancelText="否"
							>
								<u>{'删除'}</u>
							</Popconfirm>
						</div>
					</>;
				default:
					return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
			}
		};
		componentDidMount(){

			let menuAuthories =[];
			menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
			menuAuthories.map(one=>{
				if(one.name==='计量点信息管理'){
					one?.children.map(two=>{
						if(two.name==='换表'){
							this.setState({ControlButtons_1:true})
						}
					})
				}
			});
		}

		// 开通计量点
	openMp=(openMpVisible,id)=>{
			this.setState({openMpVisible,mpId:id});
	};
	// 删除计量点
	deteleMp=(mpId)=>{
		deleteMp({mpId:mpId}).then(
			response => {
				if(response?.data?.data){
					message.info("删除成功");
					this.table.refreshTable(this.state.tableParamenter_1.params);
				}else{
					message.info("删除失败")
				}
			}
		)
	};
	openChangeMeter = async (openChangeMeter,id,code)=>{
		await this.setState({openChangeMeter,changeMeterId:id,changeMeterCode:code});
		let cData={}
		cData.mpId=id
		cData.oldMeterSheetNum=code;
		await this.setState({changeData: cData});
		await this.tablePop.refreshTable();
		await this.forms.onModalClose()
	};
	toChangeMeter = async (openChangeMeter)=>{
		let rowData = this.tablePop.getSelectChange();
		if(rowData.length < 1){
			return message.error('未选择需要替换的表具')
		}else{
			let changeData = await this.forms.onFinishNotReset();
			if(!changeData) return null;
			changeData.newMeterSheetNum=rowData[0].steelNum;
			changeData.mpId=this.state.changeMeterId;
			changeData.offTime=moment(changeData.offTime?._d).format('YYYY-MM-DD HH:mm:00');
			changeData.onTime=moment(changeData.onTime?._d).format('YYYY-MM-DD HH:mm:00');
			console.log(JSON.parse(sessionStorage.getItem("user")));
			changeData.initiatorId=JSON.parse(sessionStorage.getItem("user")).id;
			changeData.initiatorName=JSON.parse(sessionStorage.getItem("user"))?.username;
			changeData.initiatorCompanyId=JSON.parse(sessionStorage.getItem("user")).orgId;
			changeData.initiatorCompanyName=JSON.parse(sessionStorage.getItem("user")).orgName;

			replaceMeter(changeData).then(
				response => {
					console.log(response);
					if(response.data.data===true){
						this.setState({openChangeMeter});
						funTraceInfo("计量点信息管理", "替换未安装表具", null,changeData)
						message.success('操作成功');
						this.table.refreshTable();
					}else{
						this.setState({openChangeMeter})
						message.error('操作失败')
						this.table.refreshTable();
					}

				}
			)
		}

	};

	toOpenMp=(openMpVisible)=>{
		openMp({mpId: this.state.mpId}).then(
				response => {
					console.log(response)
					if(response.data.data==true){
						this.setState({openMpVisible})
						funTraceInfo("计量点信息管理", "开通计量点", null,this.state.mpId)
						message.success('操作成功')
						this.table.refreshTable();
					}else{
						this.setState({openMpVisible})
						message.error('该状态下不支持此操作！')
						this.table.refreshTable();
					}

				}
			)
	};

	updatePoint = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}
		this.props.history.push({
			pathname: '/point/addPoint',
			state: data
		})
	};

	// 迁移客户计量点
	migrateMeteringPointCustomers=()=>{
		let rowData = this.table.getSelectChange();// 当前表格所选行
		console.log(rowData);

		console.log(this.state.topFormItem_1_data);
		if(rowData.length < 1) return message.error('未选择数据');

		if(rowData[0].id===this.state.topFormItem_1_data.customerId){
			return message.error('该计量点已在当前客户');
		}
		this.setState({openMpVisible_2:false});
		var param={
			mpId: this.state.topFormItem_1_data.id,
			newCustomerId: rowData[0].id,
			oldCustomerId: this.state.topFormItem_1_data.customerId || null,
			userId: JSON.parse(sessionStorage.getItem("user")).orgId
		};
		console.log(param);
		migrateMeteringPointCustomers(param).then(
			response=>{
				console.log(response.data.data);
				if(response.data.data){
					this.getData();
					// funTraceInfo("客户管理", "迁移客户计量点",rowData,param)
					return message.info("迁移客户计量点成功");
				}else{
					return message.info("迁移客户计量点失败");
				}
			}
		)
	};

	getConditionsValue_1 = async value => {
		let data = await this.forms2.onFinishNotReset();
		console.log(data);
		var tableParamenter_1 = this.state.tableParamenter_1;
		tableParamenter_1.params.customerId=data.id||null;
		tableParamenter_1.params.name=data.name||null;
		tableParamenter_1.params.businessCooperationNum=data.businessCooperationNum||null;
		this.setState({tableParamenter_1:tableParamenter_1});
		var params={} ;
		Object.assign(params,tableParamenter_1.params);
		params.customerId=data.id||null;
		params.name=data.name||null;
		params.businessCooperationNum=data.businessCooperationNum||null;
		console.log(params);
		await this.table.refreshTable(params);
	};
	setTableValue_1 = (value,text,rowData) => {
		switch (value) {
			case 'type':
				text = text*1;
				return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
			case 'state':
				text = text*1;
				return text === 1 ? '正式' : text === 2 ? '非正式' : '';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	// 拆表
	disarmMeter=(endNumValue)=>{
		console.log(endNumValue);
		if(!endNumValue){
			return message.info('表盘数不能为空');
		}
		var {selectOneData} = this.state;
		var params={

			endNumValue: endNumValue,			// 拆表-表盘示数
			meterId: selectOneData.meterId,		// 旧表id
			mpId: selectOneData.id,				// 计量点id
			offTime: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss") // 拆除时间
		};
		this.setState({openMpVisible_1:false});
		params.operator= JSON.parse(sessionStorage.getItem("user")).username;
		console.log('params');
		console.log(params);
		disarmMeter(params).then(
			response=>{
				if(response.data.data){
					// funTraceInfo("客户管理", "拆表",null,params)
					message.info("拆表成功");
					this.table.refreshTable();
					// this.getData()// 刷新表格
				}else {
					message.info("拆表失败")
				}
			}).catch(e=>{message.info("异常问题，请联系管理员"+e)})
	};
    render() {
		const {InputData}=this.state;
        return (
            <Layout className={'item_body_number_back tables'}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {pageLikeMpName}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
					<Radio.Group defaultValue="addPoint" buttonStyle="solid">
						{/*<NavLink to="/point/addPoint">*/}
						{/*	<Radio.Button value="addPoint">新增计量点</Radio.Button>*/}
						{/*</NavLink>*/}
						<Radio.Button value="updatePoint" onClick={this.updatePoint}>修改计量点</Radio.Button>
					</Radio.Group>
					{/* <Button type="primary" onClick={this.showModal}>计量点关系图</Button> */}
				</TableComponents>
				<Modal centered title="计量点关系图" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
					<p>计量点关系图</p>
				</Modal>
				<Modal
                        title='计量点开通'
                        style={{ top: 0 }}
                        visible={this.state.openMpVisible}
                        okText="确定"
                        onOk={() => this.toOpenMp(false)}           // 点击确定
                        onCancel={() => this.openMp(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认开通计量点吗？</h4>
                        </div>
                </Modal>
				<Modal
                        title='换表'
                        style={{ top: 0 }}
                        visible={this.state.openChangeMeter}
                        okText="确定"
                        onOk={() => this.toChangeMeter(false)}           // 点击确定
                        onCancel={() => this.openChangeMeter(false)}       // 点击取消
                        width={1200}
                        centered
                    >
                        <div>
							<TableComponents
								paramenter={this.state.tablePopParamenter}
								setData = {getMetersByAreaIds}
								setTableValue={this.setTableValue}
								ref={e => this.tablePop = e}
							>
							</TableComponents>

							<FormsTwo
									ref={e => this.forms = e}
									formParamenter={this.state.topFormItem}
									data={this.state.changeData}
							/>
                        </div>
                </Modal>
				<Modal
					title='计量点迁移'
					style={{ top: 0 }}
					visible={this.state.openMpVisible_2}
					okText="确定"
					onOk={() => {

						this.migrateMeteringPointCustomers();
					}}         // 点击确定

					onCancel={() => this.setState({openMpVisible_2:false})}       // 点击取消
					width={1200}
					centered
				>
					<div style={{height:'550px',}}>
						<div>
							<FormsTwo
								ref={e => this.forms1 = e}
								formParamenter={this.state.topFormItem_1}
								data={this.state.topFormItem_1_data}
							/>
						</div>
						<div style={{width:'100%',height:'1px',backgroundColor:'#E0E0E0',margin:'5px 0 10px 0'}} />
						<div>
							<div style={{position:'relative'}}>
								<div className={'topFormBox'} style={{width:'90%'}}>
									<FormsTwo
										ref={e => this.forms2 = e}
										formParamenter={this.state.topFormItem_2}
										data={this.state.topFormItem_2_data}
									/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => {
									this.getConditionsValue_1();
								}}>查询</Button>
							</div>
							<div>
								<TableComponents
									paramenter={this.state.tableParamenter_1}
									setData={getPageCustomerName}
									setTableValue={this.setTableValue_1}
									ref={e => this.table = e}
								>
								</TableComponents>
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					title='拆表'
					style={{ top: 0 }}
					visible={this.state.openMpVisible_1}
					okText="确定"
					onOk={() => this.disarmMeter(InputData)}           // 点击确定
					onCancel={() => this.setState({openMpVisible_1:false})}       // 点击取消
					width={400}
					centered
				>
					<div style={{height:'50px',padding:'10px 0 0 20px'}}>
						<div style={{float:'left',width:'20%'}}>
							<span>表盘示数</span>
						</div>
						<div style={{float:'left',width:'60%'}}>
							<Input  onChange={(title)=> this.setState({InputData:title.target.value})}/>
						</div>
					</div>
					{/*<div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>*/}
					{/*	<span>表盘示数<Input/></span>*/}
					{/*</div>*/}
				</Modal>
            </Layout>
        )
    }
}
