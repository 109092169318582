import React, { Component } from 'react'
import {Button, Input, Layout, message, Modal, Popconfirm, Table, Tabs, InputNumber, Tag} from 'antd'
import FormsTwo from "../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../components/Const";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getEntrustList} from "../../services/verifvication";
import {
    createOrUpdateBuilding,
    createOrUpdateResidentialCommunity, getCustomerByRegionIds,
    queryAdministrativeRegionByPid, queryRelationData, removeRelation, saveRelation
} from "../../services/community";
import {getDataIgnoreLevel, listDevByAreaIds} from "../../services/steamReport";
import { ArrowLeftOutlined,GlobalOutlined } from '@ant-design/icons'
import moment from 'moment'
import {getMetersBySteelNum, pageLikeMpName} from "../../services/meter";
import {getDateByUserId} from "../../services/admin";
import {NavLink} from "react-router-dom";
import {pageValveInfo} from "../../services/qrCode";
import {getUnboundValveAlarm, newGetPageCustomerName} from "../../services/customer";
import { Map, Marker, Geolocation } from '@uiw/react-amap';
const { TabPane } = Tabs;
const { AMap } = window;
// 小区管理
export default class addOrModifyCommunity extends Component {

    state = {
        // 表单参数-1-第一排
        form_1: {
            number: 4,
            labelCol: 8,
            parameter: [
                {variableName: 'name', str: '小区名',             rules: TYPE_NAME.NAME_NOT_NULL, disabled: false,},
                {variableName: 'areaId', str: '所属区域',     rules: TYPE_NAME.REQ_TREE_SELECT, disabled: false,
                    fieldNames:{label: 'name', value: 'id', children: 'children'},
                    data:[],
                    onChange: (e,a) => this.onChangeintervalType_3(e,a),
                },
                {variableName: 'propertyContactPerson', str: '物业联系人',   rules: TYPE_NAME.INPUT, disabled: false,},
                {variableName: 'propertyContactPersonTel', str: '物业联系电话',          rules: TYPE_NAME.PHONE_NUM, disabled: false,},
                {variableName: 'responsiblePerson', str: '负责人',                    rules: TYPE_NAME.INPUT, disabled: false,},
                {variableName: 'responsiblePersonTel', str: '负责人电话',             rules: TYPE_NAME.PHONE_NUM, disabled: false,},
                // {variableName: 'administrativeRegion', str: '行政区域',
                //     rules: TYPE_NAME.REQ_SELECT, disabled: false,
                //     onChange: (e,a) => this.onChangeintervalType_1(e,a),
                //     data: []},
                {variableName: 'administrativeRegion', str: '行政区域', rules: TYPE_NAME.INPUT, disabled: false,},
                {variableName: 'street', str: '街道',
                    rules: TYPE_NAME.NOT_BLANK, disabled: false,
                    onChange: (e,a) => this.onChangeintervalType_2(e,a),
                },
            ],
            data:[]
        },

        // 表单参数-3-第二排-1
        form_3: {
            number: 1,
            labelCol: 4,
            parameter: [{variableName: 'address', str: '详细地址',rules: TYPE_NAME.NAME_NOT_NULL, disabled: false,},],
            data:[]
        },
        // 表单参数-4-第二排-2
        form_4: {
            number: 2,
            labelCol: 9,
            parameter: [
                {variableName: 'latitude', str: '纬度',rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'longitude', str: '经度',rules: TYPE_NAME.NOT_BLANK, disabled: true,},
            ],
            data:[]
        },
        // 表单参数-5-第三排
        form_5: {
            number: 4,
            labelCol: 8,
            parameter: [
                {variableName: 'belongingBuilding', str: '楼栋数',rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'userNum', str: '用户数',rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'deliver', str: '小区交付日期',rules: TYPE_NAME.DATE_PICKER, disabled: false,},
                {variableName: 'status', str: '送气状态',rules: TYPE_NAME.SELECT, disabled: false,
                    data: [
                        {key:'未开通',value:'未开通'},
                        {key:'正常',value:'正常'},
                        {key:'停气',value:'停气'}
                    ]
                },
            ],
            data:[]
        },

        // 表单参数-6-弹窗-旧
        form_6: {
            number: 1,
            labelCol: 8,
            parameter: [
                {variableName: 'number', str: '栋号',rules: TYPE_NAME.ID_NUMBER, disabled: false,},
                {variableName: 'stage', str: '期数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,},
                {variableName: 'floor', str: '楼层数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,},
                {variableName: 'unit', str: '单元数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,},
                {variableName: 'households', str: '户数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,},
            ],
            data:[]
        },
        // 表单参数-6-弹窗-新
        form_6_1: {
            number: 1,
            labelCol: 8,
            parameter: [
                {variableName: 'number', str: '栋号',rules: TYPE_NAME.ID_NUMBER, disabled: false,},
                {variableName: 'stage', str: '期数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,},
                {variableName: 'floor', str: '楼层数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,},
                {variableName: 'unit', str: '单元数',rules: TYPE_NAME.NO_INPUT_NUMBER, disabled: false,
                    onChange: (e,a) => this.onChangeintervalType_4(e,a),
                },
            ],
            data:[]
        },
        // 弹窗-计量点查询-1
        form_7: {
            number: 1,
            labelCol: 8,
            parameter: [
                {variableName: 'areaId', str: '所属区域',     rules: TYPE_NAME.REQ_TREE_SELECT, disabled: false,
                    fieldNames:{label: 'name', value: 'id', children: 'children'}, data:[],
                    onChange: (e,a) => this.onChangeintervalType_3(e,a),
                },
                // {variableName: 'name', str: '计量点名称',             rules: TYPE_NAME.INPUT, disabled: false,},
            ],
            data:[]
        },
        // 弹窗-地图-1
        form_8: {
            number: 7,
            labelCol: 4,
            parameter: [
                {variableName: 'township', str: '街道',  rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'latitude', str: '纬度',  rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'longitude', str: '经度', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'address', str: '详细地址', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
            ],
            data:[]
        },


        // 表格参数_6-弹窗-设备列表
        tableParam_6: {
            overflow:true,
            manageName: "设备列表",
            // 表格行是否可选
            // rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {},
            params: {
                areaIds:[],
                page: 1,
                size: 10,
            },
            // 是否绑定左侧区域
            bindArea: false
        },

        //计量点
        tableData_1:[
            {title: '计量点代码', dataIndex: 'id', key: 'id'},
            {title: '用气用途', dataIndex: 'purpose', key: 'purpose'},
            {title: '用气性质', dataIndex: 'properties', key: 'properties'},
            {title: '用气设备', dataIndex: 'gasEquipment', key: 'gasEquipment'},
            {title: '计量点名称', dataIndex: 'mpName', key: 'mpName'},
            {title: '客户类型', dataIndex: 'customerType', key: 'customerType'},
            {title: '客户号', dataIndex: 'customerId', key: 'customerId'},
            {title: '合同号', dataIndex: 'contractNum', key: 'contractNum'},
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum'},
            {title: '表具型号', dataIndex: 'modelName', key: 'modelName'},
            {title: '是否装表', dataIndex: 'isInstall', key: 'isInstall'},
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum'},
            {title: '计量点状态', dataIndex: 'state', key: 'state'},
            {title: '计量点地址', dataIndex: 'address', key: 'address'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.id,2,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
            }
            },
        ],
        tableData_1_data:[],
        // 压力表
        tableData_2:[
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType'},
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '设备编号', dataIndex: 'serialno', key: 'serialno'},
            {title: '生产厂家', dataIndex: 'manufacture', key: 'manufacture'},
            {title: '设备状态', dataIndex: 'state', key: 'state'},
            {title: '唯一编码', dataIndex: 'uniqueEncoding', key: 'uniqueEncoding'},
            {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.devId,3,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
        tableData_2_data:[],
        // 监控
        tableData_3:[
            {title: '设备类型', dataIndex: 'devType', key: 'devType'},
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '设备编号', dataIndex: 'serialno', key: 'serialno'},
            {title: '生产厂家', dataIndex: 'manufacture', key: 'manufacture'},
            {title: '设备状态', dataIndex: 'state', key: 'state'},
            // {title: '唯一编码', dataIndex: 'uniqueEncoding', key: 'uniqueEncoding'},
            // {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?" onConfirm={()=>{this.removeRelation(record.serialno,4,record);}}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
        tableData_3_data:[],
        // 阀井
        tableData_4:[
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType'},
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '设备编号', dataIndex: 'serialno', key: 'serialno'},
            {title: '生产厂家', dataIndex: 'manufacture', key: 'manufacture'},
            {title: '设备状态', dataIndex: 'state', key: 'state'},
            {title: '唯一编码', dataIndex: 'uniqueEncoding', key: 'uniqueEncoding'},
            {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.devId,5,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
        tableData_4_data:[],

        // 楼栋
        tableData_5:[
            {title: '栋号', dataIndex: 'number', key: 'number'},
            {title: '期数', dataIndex: 'stage', key: 'stage'},
            {title: '楼层数', dataIndex: 'floor', key: 'floor'},
            {title: '单元数', dataIndex: 'unit', key: 'unit'},
            {title: '户数', dataIndex: 'households', key: 'households'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'修改'}>
                            <a onClick={async()=>{
                                await this.setState({
                                    data6:record,
                                    visible_1_title:'修改楼栋',
                                    visible_1:true,
                                    visible_1_id:record.id,
                                    tableData_6_data:record?.units??[]
                                });
                                this.forms6.onModalClose();
                            }}>修改</a>
                        </div>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.id,1,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div>
                    </>;
                }
            },
        ],
        // 表具-旧-无用
        tableData_7:[
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum'},
            {title: '安装位置', dataIndex: 'installSite', key: 'installSite'},
            {title: '表具型号', dataIndex: 'modelName', key: 'modelName'},
            {title: '表具类型', dataIndex: 'type', key: 'type'},
            {title: '表具状态', dataIndex: 'state', key: 'state'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'修改'}>
                            <a onClick={async()=>{
                                await this.setState({
                                    data6:record,
                                    visible_1_title:'修改表具',
                                    visible_1:true,
                                    visible_1_id:record.id,
                                    tableData_6_data:record?.units??[]
                                });
                                this.forms6.onModalClose();
                            }}>修改</a>
                        </div>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.id,1,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div>
                    </>;
                }
            },
        ],
        // 表具-新-现用
        tableData_7_1:[
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum'},
            {title: '安装位置', dataIndex: 'installSite', key: 'installSite'},
            {title: '表具型号', dataIndex: 'modelName', key: 'modelName'},
            {title: '表具类型', dataIndex: 'type', key: 'type'},
            {title: '表具状态', dataIndex: 'state', key: 'state'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.serialno,6,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
        tableData_7_data:[],//表具表格数据

        tableParamenter_1:{
            manageName: "居民安全自检管理",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'SafetySelfInspection',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '居民安全自检管理',
            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系电话',
                address: '详细地址',
                selfInspectionTime: '最近自检时间',
                selfInspectionRequiredTime: '最近应自检日期',
                selfCheckStatus: '自检状态',
                gasApplianceStatus: '燃气具状态',
                edit: '安检记录',
            },
            params: {
                type:1,
                regionManagementId:this.props.location?.state?.rowData?.id?[this.props.location?.state?.rowData.id]:[],
                page: 1,
                size:5,
            }
        },
        tableParamenter_2:{
            manageName: "工商户安全自检管理",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'SafetySelfInspection',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '居民安全自检管理',
            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系电话',
                address: '详细地址',
                selfInspectionTime: '最近自检时间',
                selfInspectionRequiredTime: '最近应自检日期',
                selfCheckStatus: '自检状态',
                gasApplianceStatus: '燃气具状态',
                edit: '安检记录',
            },
            params: {
                type:2,
                regionManagementId:this.props.location?.state?.rowData?.id?[this.props.location?.state?.rowData.id]:[],
                page: 1,
                size:5,
            }
        },


        tableData_6_data:[],// 弹窗-楼栋表格数据
        tableData_6_name:null,
        tableData_6_households:0,

        tableData_loading:false,
        data1:{},
        data3:{},
        data4:{},
        data5:{},
        data6:{},// 弹窗-计量点查询
        data7:{},// 弹窗-地图
        areaName:null,


        switchButton:1,// 第一层按钮样式

        // 弹窗样式
        visible_1_id:null,
        visible_1_title:'',
        visible_1:false,// 弹窗开关-1
        visible_1_1:false,// 弹窗开关-1
        visible_2:false,// 弹窗开关-2
        visible_2_type:1,
        visible_3:false,// 弹窗开关-3
        longitude:null,
        latitude:null,
        showMap:[113.394054,28.223727],
        township:null,

        inputStatus:null,// 弹窗-楼栋-户号提示
    };


    async componentDidMount() {
        var rowData = this.props.location?.state?.rowData;
        // this.queryAdministrativeRegionByPid();              // 查询行政区域-暂不用使用手写
        this.getDateByUserId(rowData?.areaId??null);    // 获取所属区域
        this.queryRelationData(2);                          // 查询表格
        //初始化修改小区参数配置
        if(rowData){
            rowData.deliver= rowData.deliver? moment(rowData.deliver):null;
            await this.setState({
                data1:rowData,  // 表格第1行
                data3:rowData,  // 表格第2行
                data4:rowData,  // 表格第3行
                data5:rowData,  // 表格第4行
                longitude:rowData.longitude??null,                          // 经度
                latitude:rowData.latitude??null,                            // 维度
                showMap:[rowData.longitude??null,rowData.latitude??null]    // 地图坐标
            });

            this.forms2.onModalClose();
            this.forms3.onModalClose();
            this.forms4.onModalClose();
            this.forms5.onModalClose();
            this.forms2.setFieldsValue('areaId',rowData?.areaId?rowData.areaId.toString():null);
            this.forms5.setFieldsValue('status',rowData?.status?rowData.status.toString():null);
        }
    }

    // 移除小区关系-能直接删除楼栋
    removeRelation=(relationId,type,data)=>{
        // var rowData = this.props.location?.state?.rowData;
        // if(!rowData?.id){return false;}
        var params = {
            'id':data.id,	    // 未绑定的设备id
            'installer':JSON.parse(sessionStorage.getItem("user")).realName??null,          // 安装人
            'installerTel':JSON.parse(sessionStorage.getItem("user"))?.phone??null,         //安装联系电话
            businessType:'小区',

            'devId':data?.devId?data?.devId:null,                                               // device服务主键id
            'devType':type===3?'10171':type===4?'10209':type===5?'10200':null,                  // 设备类型
            'hdstno':data?.hdstno??null,	                                                     // 厂家编号
            'serialno':data?.serialno??null,                                                 // 序列号/设备编号

            "regionManagementId":this.props.location?.state?.rowData.id,
            "relationId": type===1?relationId:
                type===3?data?.devId:
                    type===4?data?.serialno:
                        type===5?data?.devId:
                            type===2?relationId:
                                type===6?data?.steelNum:
                                null,  // 设备类型
            "type": type
        };
        removeRelation(params).then(
            response => {
                if(response?.data?.data){
                    this.queryRelationData(type);

                }else{
                    message.info('删除失败')
                }
            }
        )
    };

    // 获取区域
    getDateByUserId=(areaId)=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    this.state.form_1.parameter[1].data=response?.data;
                    this.state.form_7.parameter[0].data=response?.data;
                    if(areaId) {
                        if(this.props.location?.state?.rowData){
                            var rowData = this.props.location?.state?.rowData;
                            var tableParam6 = this.state.tableParam_6;
                            var treeAreaIds=[];
                            this.forTreeArea(response?.data,areaId,treeAreaIds);
                            tableParam6.params.areaIds=treeAreaIds;
                            this.setState({tableParam_6:tableParam6});
                        }
                    }


                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };



    // 获取区域
    getDataIgnoreLevel=()=>{
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        selectArry.push({key:data[i].id,value:data[i].name})
                    }
                }
                this.state.form_1.parameter[1].data=selectArry;
            }
        );
    };


    onChangeintervalType_1 = async (e,a)=>{
        if(!e){return false;}
        var {latitude,longitude} = this.state;
        var form1 = this.state.form_1;
        var text1= "";
        form1.parameter[6]?.data.map(one=>{
            if(one.name===e){
                console.log(one);
                text1= one?.mergerName?one.mergerName.split(',').join(""):"";
                this.forms4.setFieldsValue('latitude',one.lat);
                this.forms4.setFieldsValue('longitude',one.lng);
                latitude=one.lat;
                longitude=one.lng;
                this.setState({latitude:latitude,longitude:longitude,showMap:[longitude,latitude]})
            }});
        this.forms2.setFieldsValue('street',null);
        this.forms3.setFieldsValue('address',text1);
    };
    onChangeintervalType_2= async (e,a)=>{
        var text1= e?.target?.value??null;
        var schedulePeriod = await this.forms2.getData('administrativeRegion');
        var form1 = this.state.form_1;
        var text2= null;
        form1.parameter[6]?.data.map(one=>{if(one.key===schedulePeriod){text2= one?.mergerName?one.mergerName.split(',').join(""):"";}});
        if(text2){text2=text2+text1;}
        this.forms3.setFieldsValue('address',text2);
    };
    onChangeintervalType_3= async (e,a)=>{
        if(!e){return false;}
        var tableParam6 = this.state.tableParam_6;
        var treeAreaIds=[];
        this.forTreeArea(this.state.form_1.parameter[1].data,e,treeAreaIds);
        tableParam6.params.areaIds=treeAreaIds;
        this.setState({areaName:a[0],tableParam_6:tableParam6});
    };
    onChangeintervalType_4= async (e,a)=>{
        var tableData_6_data = [...this.state.tableData_6_data];
        tableData_6_data=[];
        if(e){for(var i=1; i<e+1; i++){tableData_6_data.push({name:i,households:0,display:false})}}
        this.setState({tableData_6_data:tableData_6_data})
    };
    forTreeArea = (data,key=String,areaIds=[]) =>{

        data.map(one=>{
            if(one.id.toString().indexOf(key)!==-1){
                areaIds.push(one.id);
                if(one?.children){
                    this.addAllTreeArea(one?.children,areaIds);
                }
            }else if(one?.children){
                this.forTreeArea(one?.children,key,areaIds);
            }
        });

        return areaIds;
    };
    addAllTreeArea = (data,areaIds=[]) =>{
        data.map(one=>{
            areaIds.push(one.id);
            if(one?.children){
                this.addAllTreeArea(one?.children,areaIds);
            }
        });
        return areaIds;
    };
// 查询行政区域
    queryAdministrativeRegionByPid=()=> {
        queryAdministrativeRegionByPid({pid:1828}).then(
            response => {
                if(response?.data?.data){
                    var form = this.state.form_1;
                    const data = response.data.data;
                    data.map(one=>{
                        one.key=one?.name??null;
                        one.value=one?.name??null;
                        form.parameter[6].data.push(one)
                    });
                    this.setState({form_1:form})
                }
            })
    };

//  创建-修改小区
    createOrUpdateResidentialCommunity= async ()=>{
        var forms1 = await this.forms2.onFinishNotReset();
        var forms2 = await this.forms3.onFinishNotReset();
        var forms3 = await this.forms4.onFinishNotReset();
        var forms4 = await this.forms5.onFinishNotReset();
        var {areaName} = this.state;
        if(!(forms1 && forms2 && forms3 && forms4)){return false;}
        var params=Object.assign({}, forms1, forms2,forms3,forms4);

        if(areaName){
            params.areaName = areaName;
        }else{
            params.areaId = this.props.location?.state?.rowData?.areaId;
            params.areaName = this.props.location?.state?.rowData?.areaName;
        }
        params.deliver=moment(params.deliver).format('YYYY-MM-DD HH:mm:ss');
        if(this.props.location?.state?.rowData){params.id=this.props.location?.state?.rowData?.id??null;}
        Modal.confirm({
            title: `是否确认操作`,
            onOk: () => {
                createOrUpdateResidentialCommunity(params).then(
                    response => {
                        if(response?.data?.data){
                            message.success({ content: params?.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
                            return this.props.history.go(-1);
                        }else{
                            message.info('保存失败'+response?.data?.responseInfo?.description);
                        }
                    }
                )
            }
        });

    };

    // 创建-修改楼栋
    createOrUpdateBuilding = async (id) => {
        var formParam = await this.forms6.onFinish();
        var tableData6Data = this.state.tableData_6_data;
        console.log('1');
        if(!formParam){ return false;}
        console.log('2');
        formParam=Object.assign({},formParam,{residentialCommunityId:this.props.location?.state?.rowData?.id??null});
        if(this.state.visible_1_id){formParam=Object.assign({},formParam,{id:this.state.visible_1_id})}
        formParam.units=tableData6Data;
        if(formParam?.id){
            formParam.units.map(one=>{
                one=Object.assign({},one,{buildingId:formParam?.id??null});
            });
        }
        console.log(formParam);
        createOrUpdateBuilding(formParam).then(
            response => {
                if(response?.data?.data){
                    if(!this.state.visible_1_id){
                        this.saveRelation(response?.data?.data,1,null); // 添加小区关系
                    }else{
                        this.queryRelationData(1);// 查询表格
                    }
                    this.setState({visible_1:false});
                }else{
                    message.info('操作失败'+response?.data?.responseInfo?.description);
                }

            }
        )
    };

    // 添加小区关系 - type类型（1：楼栋，2：计量点，3：压力表10171，4：监控10209，5：阀进10200
    saveRelation=(relationId,type,rowData)=>{
        console.log(relationId);
        if(!relationId){return false;}
        if(!type){return false;}

        var params={
            'installer':JSON.parse(sessionStorage.getItem("user")).realName??null,          // 安装人
            'installerTel':JSON.parse(sessionStorage.getItem("user"))?.phone??null,         //安装联系电话
            'id':relationId,	    // 未绑定的设备id
            // 'devCode':null,         // 设备验证码(莹石设备需要)
            businessType:'小区',
            'devId':rowData?.devId?rowData?.devId:null,                         // device服务主键id
            'devType':type===3?'10171':type===4?'10209':type===5?'10200':null,  // 设备类型
            'hdstno':rowData?.hdstno??null,	                                    // 厂家编号
            'serialno':rowData?.serialno??null,                                 // 序列号/设备编号

            "regionManagementId": this.props.location?.state?.rowData.id,   // 小区id
            // "relationId": relationId,                                    // 关联id
            "relationId" :type===1?relationId:
                type===3?rowData?.devId:
                    type===4?rowData?.serialno:
                        type===5?rowData?.devId:
                            type===6?rowData?.steelNum:
                                null,  // 设备类型,                          // 关联id
            "type": type                                                    // 类型
        };
        saveRelation(params).then(
            response => {
                if(response?.data?.data){
                    message.info('操作成功')
                }else{
                    message.info('操作失败'+response?.data?.responseInfo?.description);
                }
                this?.table6?.refreshTable(this.state.tableParam_6?.params);
                this.queryRelationData(type);
            }
        );

    };

    setTableValue_6 = (value,text,rowData) => {

        // var id=rowData.id;
        // if(this.state.visible_2_type===4){
        //     id=rowData.serialno;
        // }else if(this.state.visible_2_type!==2){
        //     id=rowData.devId;
        // }

        if(text === 'null') return '-';
        switch (value) {

            case 'edit':
                return <>
                    <div className={'tableLink'} title={'绑定'}>
                        <Popconfirm title="是否绑定?"
                                    onConfirm={()=>{

                                        this.saveRelation(rowData.id,this.state.visible_2_type,rowData)
                                    }}
                                    okText="是" cancelText="否">
                            <a>绑定</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 弹窗表格切换
    setTableHead=(type)=>{
        var tableParam6 = this.state.tableParam_6;
        tableParam6.header=type===2?
            {   id: '计量点代码',
                installNumber: '安装号',
                purpose: '用气用途',
                properties: '用气性质',
                gasEquipment: '用气设备',
                name: '计量点名称',
                customerId: '客户号',
                contractNumber: '合同号',
                steelNum: '表钢号',
                state:'状态',
                edit:'操作',
            }
            :
            tableParam6.header=type===4?
                {
                    devName:'设备名',
                    serialno:'设备编号',
                    manufacture: '生产厂家',
                    state:'设备状态',
                    uniqueEncoding: '唯一编码',
                    installPosition: '安装位置',
                    edit: '操作',
                }
                    :
                tableParam6.header=type===6?
                    {
                        steelNum: '表钢号',
                        installSite: '安装位置',
                        modelName: '表具型号',
                        type: '表具类型',
                        state: '表具状态',
                        edit: '操作',
                    }
                    :
                {
                    devModelName:'设备型号',
                    // devType: '设备类型',
                    devName:'设备名',
                    serialno:'设备编号',
                    manufacture: '生产厂家',
                    state:'设备状态',
                    // uniqueEncoding: '唯一编码',
                    installPosition: '安装位置',
                    comStatus: '通信状态',
                    systemTime: '通信时间',
                    edit: '操作',}
            ;
        this.setState({tableParam_6:tableParam6})
    };


    // 查询表格
    queryRelationData=(e)=>{
        var rowData = this.props.location?.state?.rowData;
        if(!rowData){return false;}
        var params={

            "regionManagementId": rowData?.id,
            "relationId": null,
            "type": e,
            // 'areaIds':this.props.location?.state?.rowData.id??null,
            businessType:'小区',
        };
        this.setState({tableData_loading:true});
        queryRelationData(params).then(
            response => {
                if(response?.data?.data){
                    // buildingList     楼顶
                    // pointList        计量点
                    // valveAlarmLists  压力表/阀进/监控
                    // 楼顶表数据，计量点表数据，压力表/阀进/监控表数据
                    var data =response?.data?.data;
                    console.log(data?.meterDevices);
                    this.setState({
                        tableData_1_data:data?.buildingList??[],    // 楼顶
                        tableData_2_data:data?.pointList??[],       // 计量点
                        tableData_3_data:data?.valveAlarmLists??[], // 压力表/阀进/监控
                        tableData_7_data:data?.meterDevices??[]
                    })
                }
                this.setState({    tableData_loading:false})
            }
        )
    };
    setTableValue = (value,text,rowData) => {
        switch (value) {

            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';

            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };


    render() {
        const {switchButton,form_1,tableData_6_households,tableData_6_name,inputStatus} = this.state;
        const tableData_6=[
            {title: '单元号', dataIndex: 'name', key: 'name',
                render: (text, record) =>{
                    if(record.display){
                        return<><Input status={inputStatus} defaultValue={text} onChange={(e)=>{this.setState({tableData_6_name:e.target.value})}}/></>
                    }
                    else {return<>{text}</>}}
                    },
            {title: '户数', dataIndex: 'households', key: 'households',
                render: (text, record) =>{
                if(record.display){
                    return<><InputNumber min={0} defaultValue={text} onChange={(e)=>{this.setState({tableData_6_households:e})}}/></>
                }
                else {return<>{text}</>}}
                },
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'修改'}>
                            <a onClick={async()=>{
                                var tableData_6_data = this.state.tableData_6_data;
                                // 判断当前数据有无修改
                                if(record.display){
                                    // 有数值变化执行
                                    if(tableData_6_name){
                                        // 判断变化的数据与目前的行数据有没有差异
                                        if(record.name.indexOf(tableData_6_name.toString())<0){
                                            if(tableData_6_data.find(one=>{if(one.name.indexOf(tableData_6_name)!==-1)return true})){
                                                this.setState({inputStatus:'error'});
                                                return false;
                                            }
                                        }
                                    }

                                    tableData_6_data.map(one=>{
                                        if(one.name===record.name){
                                            one.name=tableData_6_name??record.name;
                                            one.households=tableData_6_households??record.households;
                                            one.display=false;
                                        }});
                                }else{
                                    tableData_6_data.map(one=>{
                                        if(one.name===record.name){
                                            this.setState({tableData_6_name:null,tableData_6_households:null,inputStatus:null});
                                            one.display=true;
                                        }});
                                }

                            }}>{record.display?'保存':'修改'}</a>
                        </div>

                        <div className={'tableLink'} title={'删除'}>
                            <Popconfirm title="是否删除?"
                                        onConfirm={()=>{
                                            var tableData_6_data = [...this.state.tableData_6_data];
                                            for(var i=0;i<tableData_6_data.length;i++){
                                                if(tableData_6_data[i].name===record.name){
                                                    tableData_6_data.splice(i,1)
                                                }
                                            }
                                            this.setState({tableData_6_data:tableData_6_data});
                                            this.forms6.setFieldsValue('unit',tableData_6_data.length);
                                        }}
                                        okText="是" cancelText="否">
                                <a>删除</a>
                            </Popconfirm>
                        </div>
                    </>;
                }
            },
        ];

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
{/*主体----------------*/}
                <div style={{width:'100%',height:'100%'}}>
                    <div className={'div_back_arrow'}>
					<span className={'back_arrow'}
                          onClick={() =>
                              // this.props.history.replace('/customer/clientBusiness')
                              this.props.history.go(-1)
                          }
                    ><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                        <span className={'font_size2'}>返回上一页</span>
                    </div>
{/*上部分---------------*/}
                    <div style={{marginLeft:'30px',width:"60px",backgroundColor:'#fff'}}>
                        <span>基本信息</span>
                    </div>
                    <div style={{width:'100%',height:'286px',padding:'10px'}}>
                        {/*<div style={{ position: 'absolute',top:'38px',left:'30px',backgroundColor:'#fff'}}>*/}
                        {/*    <span>基本信息</span>*/}
                        {/*</div>*/}
{/*表单------------------*/}
                        <div style={{marginTop:'-22px',width:'100%',height:'100%',paddingTop: '20px', border:'1px solid rgb(240 242 245)'}}>
                            {/*第一层*/}
                            <div style={{width:'100%',height:'100px'}}>
                                <div style={{width:'98%',height:'100px',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms2 = e}
                                        formParamenter={this.state.form_1}
                                        data={this.state.data1}
                                    />
                                </div>
                                <div style={{width:'2%',height:'100px',float:'left',paddingTop:"55px",textAlign:'center'}}>
                                    <div style={{width:'100%',height:'34px',paddingTop:"5px",cursor:'pointer',
                                        borderRadius: '0px 3px 3px 0px',backgroundColor:'#1890ff'}}
                                         onClick={ async () => {
                                             await this.setState({
                                                 visible_1_title:'地图',
                                                 visible_3:true,
                                                 latitude:document.getElementById('latitude').value??null,
                                                 longitude:document.getElementById('longitude').value??null,
                                             });
                                             //区
                                             document.getElementById('district').value= this.forms2.getData('administrativeRegion')??null;
                                             //街道
                                             document.getElementById('township').value= this.forms2.getData('street')??null;
                                             //详细地址
                                             document.getElementById('latNum').value = this.forms3.getData('address')??null;
                                             //纬度
                                             document.getElementById('lat').value = this.forms3.getData('latitude')??null;
                                             //经度
                                             document.getElementById('lng').value = this.forms3.getData('longitude')??null;
                                         }}
                                    >
                                        <GlobalOutlined style={{color:'#fff'}}/>
                                    </div>
                                </div>
                            </div>
                            {/*第二层*/}
                            <div style={{width:'100%',height:'50px',marginTop:'10px'}}>
                                {/*第二层-1*/}
                                <div style={{width:'48.2%',height:'100%',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms3 = e}
                                        formParamenter={this.state.form_3}
                                        data={this.state.data3}
                                    />
                                </div>
                                {/*第二层-2*/}
                                <div style={{width:'49.7%',height:'100%',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms4 = e}
                                        formParamenter={this.state.form_4}
                                        data={this.state.data4}
                                    />
                                </div>
                            </div>
                            {/*第三层*/}
                            <div style={{width:'98%',height:'40px'}}>
                                <FormsTwo
                                    ref={e => this.forms5 = e}
                                    formParamenter={this.state.form_5}
                                    data={this.state.data5}
                                />
                            </div>
                            {/*第四层*/}
                            <div style={{width:'98%',height:'40px'}}>
                                <Button type="primary" style={{float:'right'}}
                                        onClick={()=>{
                                            this.createOrUpdateResidentialCommunity();
                                        }}
                                >
                                    保存
                                </Button>
                            </div>
                        </div>
                    </div>
{/*下部分---------------*/}
                    <div style={{marginTop:'-22px',marginLeft:'30px',width:"60px",backgroundColor:'#fff',display: this.props.location?.state?.selectWidth?'none':null}}>
                        <span>关联信息</span>
                    </div>

                    <div style={{marginTop:'-22px',width:'100%',height:'60%',padding:'10px',

                        display: this.props.location?.state?.selectWidth?'none':null}}
                    >
                        {/*内容*/}
                        <div style={{width:'100%',height:'100%', border:'1px solid rgb(240 242 245)',padding:'10px 5px 5px 5px'}}>
{/*第一层按钮-------------------------*/}
                            <div style={{width:'100%',height:'40px'}}>
                                <div style={{width:'100px',height:'100%',textAlign: 'center',float:'left'}}>
                                    <span style={{fontSize:'16px',fontWeight: 'bold',cursor:'pointer',color:switchButton===1?'#79de96':'#898b96'}}
                                          onClick={()=>{
                                              console.log('this.state.visible_2_type');
                                              console.log(this.state.visible_2_type);
                                              this.queryRelationData(this.state.visible_2_type);
                                              this.setState({switchButton:1});//页面样式
                                          }}
                                    >设备</span>
                                </div>
                                <div style={{width:'100px',height:'100%',textAlign: 'center',float:'left'}}>
                                    <span style={{fontSize:'16px',fontWeight: 'bold',cursor:'pointer',color:switchButton===2?'#79de96':'#898b96'}}
                                          onClick={()=>{
                                              this.queryRelationData(1);
                                              this.setState({switchButton:2});
                                          }}
                                    >楼栋</span>
                                </div>
                                <div style={{width:'100px',height:'100%',textAlign: 'center',float:'left'}}>
                                    <span style={{fontSize:'16px',fontWeight: 'bold',cursor:'pointer',color:switchButton===3?'#79de96':'#898b96'}}
                                          onClick={()=>{
                                              this.setState({switchButton:3});
                                          }}
                                    >客户</span>
                                </div>
                            </div>
{/*第二层页面按钮-1---------------------------*/}
                            <div style={{width:'100%',height:'92%',display: switchButton===1?null:'none'}}>
                                <Tabs type="card"
                                      onTabClick={(e,a)=>{
                                          this.setState({visible_2_type:e});
                                          this.queryRelationData(e);
                                      }}>
{/*计量点--------------------------*/}
                                    <TabPane tab="计量点" key="2" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      console.log(this.forms2.getData('areaId').toString());
                                                      this.setState({
                                                          visible_1_title:'绑定计量点',
                                                          visible_2:true,
                                                          visible_2_type:2,
                                                          data6:{areaId:this.forms2.getData('areaId').toString()}// 弹窗-地址查询表单数据
                                                      });
                                                      await this.setTableHead(2); // 弹窗表格表头切换
                                                      var rowData = this.props.location?.state?.rowData;
                                                      var promise = await this.forms2.onFinishNotReset();// 获取小区表单的所属区域
                                                      // 区域id
                                                      this.onChangeintervalType_3(promise.areaId,[this.state.areaName??rowData.areaName]);

                                                  }}>
                                                绑定计量点
                                            </span>
                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{this.queryRelationData(2);}}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <Table
                                                columns={this.state.tableData_1}
                                                dataSource={this.state.tableData_2_data}
                                                bordered
                                                loading={this.state.tableData_loading}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    total:this.state.tableData_2_data.length,
                                                    pageSize:4,
                                                    showTotal: (count = this.state.tableData_2_data.length)=>{
                                                        return '共'+this.state.tableData_2_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['4']
                                                }}
                                            />

                                        </div>
                                    </TabPane>
{/*压力表---------------------------*/}
                                    <TabPane tab="压力表" key="3" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam6 = this.state.tableParam_6;
                                                      tableParam6.params.devType=10171;
                                                      await this.setState({
                                                          tableParam_6:tableParam6,visible_1_title:'绑定压力表',
                                                          visible_2:true,visible_2_type:3,
                                                          data6:{areaId:this.forms2.getData('areaId').toString()}
                                                      });
                                                      this.forms7.onModalClose();
                                                      this.setTableHead(3);
                                                      this.table6.refreshTable(this.state.tableParam_6.params);
                                                  }}>
                                                绑定压力表
                                            </span>
                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{this.queryRelationData(3);}}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <Table
                                                columns={this.state.tableData_2}
                                                dataSource={this.state.tableData_3_data}
                                                bordered
                                                loading={this.state.tableData_loading}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    total:this.state.tableData_3_data.length??0,
                                                    pageSize:4,
                                                    showTotal: (count = this.state.tableData_3_data.length??0)=>{
                                                        return '共'+this.state.tableData_3_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['4']
                                                }}
                                            />
                                        </div>
                                    </TabPane>
{/*监控----------------------------*/}
                                    <TabPane tab="监控" key="4" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam6 = this.state.tableParam_6;
                                                      tableParam6.params.devType=10209;
                                                      await this.setState({tableParam_6:tableParam6,visible_1_title:'绑定监控',
                                                          visible_2:true,visible_2_type:4,
                                                          data6:{areaId:this.forms2.getData('areaId').toString()}
                                                      });
                                                      this.forms7.onModalClose();
                                                      this.setTableHead(4);// 弹窗表格切换
                                                      this.table6.refreshTable(this.state.tableParam_6.params);;
                                                  }}>
                                                绑定监控
                                            </span>
                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{this.queryRelationData(4);}}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <Table
                                                columns={this.state.tableData_3}
                                                dataSource={this.state.tableData_3_data}
                                                bordered
                                                loading={this.state.tableData_loading}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    total:this.state.tableData_3_data.length,
                                                    pageSize:4,
                                                    showTotal: (count = this.state.tableData_3_data.length)=>{
                                                        return '共'+this.state.tableData_3_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['4']
                                                }}
                                            />
                                        </div>
                                    </TabPane>
{/*阀井------------------------------*/}
                                    <TabPane tab="阀井" key="5" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam6 = this.state.tableParam_6;
                                                      tableParam6.params.devType=10200;
                                                      await this.setState({tableParam_6:tableParam6, visible_1_title:'绑定阀井',
                                                          visible_2:true,visible_2_type:5,
                                                          data6:{areaId:this.forms2.getData('areaId').toString()}
                                                      });
                                                      this.forms7.onModalClose();
                                                      this.setTableHead(5);
                                                      this.table6.refreshTable(this.state.tableParam_6.params);
                                                  }}>
                                                绑定阀井
                                            </span>
                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{
                                                      this.queryRelationData(5);
                                                  }}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <Table
                                                columns={this.state.tableData_4}
                                                dataSource={this.state.tableData_3_data}
                                                bordered
                                                loading={this.state.tableData_loading}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    total:this.state.tableData_3_data.length,
                                                    pageSize:4,
                                                    showTotal: (count = this.state.tableData_3_data.length)=>{
                                                        return '共'+this.state.tableData_3_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['4']
                                                }}
                                            />
                                        </div>
                                    </TabPane>
{/*表具------------------------------*/}
                                    <TabPane tab="表具" key="6" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam6 = this.state.tableParam_6;
                                                      await this.setState({
                                                          tableParam_6:tableParam6,
                                                          visible_1_title:'绑定表具',
                                                          visible_2:true,visible_2_type:6,
                                                          data6:{areaId:this.forms2.getData('areaId').toString()}
                                                      });
                                                      this.forms7.onModalClose();
                                                      this.setTableHead(6);
                                                      this.table6.refreshTable(this.state.tableParam_6.params);
                                                  }}>
                                                绑定表具
                                            </span>
                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{
                                                      this.queryRelationData(6);
                                                  }}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <Table
                                                columns={this.state.tableData_7_1}
                                                dataSource={this.state.tableData_7_data}
                                                bordered
                                                loading={this.state.tableData_loading}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    total:this.state.tableData_7_data.length,
                                                    pageSize:4,
                                                    showTotal: (count = this.state.tableData_7_data.length)=>{
                                                        return '共'+this.state.tableData_7_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['4']
                                                }}
                                            />
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
{/*第二层页面按钮-2---------------------------*/}
                            <div style={{width:'100%',height:'92%',display: switchButton===2?null:'none'}}>
{/*楼栋---------*/}
                                <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      await this.setState({visible_1_id:null,data6:null,visible_1_title:'添加楼栋',visible_1:true});
                                                      await this.forms6.onModalClose();
                                                  }}>添加楼栋</span>
                                    <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                          onClick={()=>{this.queryRelationData(1);}}>刷新</span>
                                </div>
                                <div style={{width:'100%',height:'435px'}}>
                                    <Table
                                        columns={this.state.tableData_5}
                                        dataSource={this.state.tableData_1_data}
                                        bordered
                                        loading={this.state.tableData_loading}
                                        pagination = {{
                                            showSizeChanger:true,
                                            showQuickJumper: true,
                                            total:this.state.tableData_1_data.length,
                                            pageSize:6,
                                            showTotal: (count = this.state.tableData_1_data.length)=>{
                                                return '共'+this.state.tableData_1_data.length+'条数据'
                                            },
                                            defaultCurrent: 1,
                                            pageSizeOptions: ['6']
                                        }}
                                    />
                                </div>
                            </div>
{/*第二层页面按钮-3---------------------------*/}
                            <div style={{width:'100%',height:'92%',display: switchButton===3?null:'none'}}>
 {/*客户-------------------------*/}
                                <Tabs type="card"
                                      onTabClick={(e,a)=>{

                                      }}>
                                    {/*计量点--------------------------*/}
                                    <TabPane tab="居民" key="1" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>

                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{this.table.refreshTable(this.state.tableParamenter_1.params)}}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <TableComponents
                                                // id={'customerId'}
                                                paramenter={this.state.tableParamenter_1}
                                                setData={getCustomerByRegionIds}
                                                setTableValue={this.setTableValue}
                                                ref={e => this.table = e}
                                            />

                                        </div>
                                    </TabPane>
                                    <TabPane tab="工商户" key="2" style={{width: '100%',height: '100%'}}>
                                        <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>

                                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={()=>{this.table1.refreshTable(this.state.tableParamenter_2.params)}}>
                                                刷新
                                            </span>
                                        </div>
                                        <div style={{width:'100%',height:'380px'}}>
                                            <TableComponents
                                                // id={'customerId'}
                                                paramenter={this.state.tableParamenter_2}
                                                setData={getCustomerByRegionIds}
                                                setTableValue={this.setTableValue}
                                                ref={e => this.table1 = e}
                                            />

                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>



{/*弹窗-楼栋----------------*/}
                <Modal
                    title={this.state.visible_1_title}
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_1}
                    onOk={ async () => {this.createOrUpdateBuilding();}}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false,tableData_6_data:[]})}	// 点击取消
                    width={700}
                    centered
                    footer={null}
                >
                    <div style={{width:'100%',height:'600px'}}>
                        <div style={{width:'100%',height:'40%'}}>
                            <div style={{width:'80%',height:'100%',float:'left'}}>
                                <FormsTwo
                                    ref={e => this.forms6 = e}
                                    formParamenter={this.state.form_6_1}
                                    data={this.state.data6}
                                />
                            </div>
                            <div style={{width:'20%',height:'100%',float:'left'}}>
                                <Button style={{top:'168px',left:'30px'}}
                                        onClick={ async ()=>{
                                            this.createOrUpdateBuilding();
                                        }}
                                >
                                    保存
                                </Button>
                            </div>
                        </div>
                        {/*-----*/}
                        <div  style={{width:'100%',height:'1px',backgroundColor:'#cccccc'}}/>
                        <div style={{width:'100%',height:'50%',paddingTop:'10px'}}>
                            <div style={{width:'100%',height:'18%'}}>
                                <span style={{fontSize:'18px',fontWeight: 'bold'}}>楼栋单元</span>
                                <Button style={{left:'495px'}}
                                        onClick={()=>{
                                            var tableData_6_data = [...this.state.tableData_6_data];
                                            let lastElement = tableData_6_data.slice(-1)[0];
                                            tableData_6_data.push({name:Number(lastElement?.name??0)+1, households:0,display:false});
                                            this.setState({tableData_6_data:tableData_6_data});
                                            this.forms6.setFieldsValue('unit',tableData_6_data.length);
                                        }}
                                >
                                    添加
                                </Button>
                            </div>
                            <div style={{width:'100%',height:'72%'}}>
                                <Table
                                    columns={tableData_6}
                                    dataSource={this.state.tableData_6_data}
                                    bordered
                                    loading={this.state.tableData_loading}
                                    pagination = {{
                                        showSizeChanger:true,
                                        showQuickJumper: true,
                                        total:this.state.tableData_3_data.length,
                                        pageSize:4,
                                        showTotal: (count = this.state.tableData_3_data.length)=>{
                                            return '共'+this.state.tableData_3_data.length+'条数据'
                                        },
                                        defaultCurrent: 1,
                                        pageSizeOptions: ['4']
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </Modal>
{/*弹窗-绑定设备-----------*/}
                <Modal
                    title={this.state.visible_1_title}
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_2}
                    onOk={ async () => {
                        this.setState({visible_2:false});
                        this.queryRelationData(this.state.visible_2_type);
                    }}           	// 点击确定
                    onCancel={ async  () =>{
                        this.setState({visible_2:false});
                        var data = await this.forms2.onFinishNotReset();
                        console.log(data);

                        this.queryRelationData(this.state.visible_2_type);
                    }}	// 点击取消
                    width={1400}
                    centered
                >
                    <div style={{height:'100%',backgroundColor:"#fff"}}>
                        <div style={{width:'100%',paddingLeft:'10px',paddingRight:'20px'}}>
                            <div style={{float:'left',
                                // display:this.state.visible_2_type===2?null:'none'
                            }}>
                                <div style={{width:'300px',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms7 = e}
                                        formParamenter={this.state.form_7}
                                        data={this.state.data6}
                                    />
                                </div>
                                <div style={{width:'100px',float:'left'}}>
                                    <Button type="primary"
                                            onClick={async ()=>{
                                                var params = await this.forms7.onFinishNotReset();
                                                var tableParam6 = this.state.tableParam_6;
                                                tableParam6.params=Object.assign({},tableParam6.params,params);
                                                this.table6.refreshTable( tableParam6.params );
                                            }}
                                    >
                                        查询
                                    </Button>
                                </div>
                            </div>

                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                  onClick={()=>{this.table6.refreshTable(this.state.tableParam_6.params);}}>
                                刷新
                            </span>
                        </div>
                        <div style={{width:'100%',height:'500px',overflowY: 'auto'}}>
                            <TableComponents
                                paramenter={this.state.tableParam_6}
                                setData = {this.state.visible_2_type===2?pageLikeMpName:this.state.visible_2_type===6?getMetersBySteelNum:getUnboundValveAlarm}
                                setTableValue={this.setTableValue_6}
                                ref={e => this.table6 = e}
                            >
                            </TableComponents>
                        </div>
                    </div>
                </Modal>
{/*弹窗-地图坐标-----------*/}
                <Modal
                    title={this.state.visible_1_title}
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_3}
                    onOk={ async () => {
                        this.forms2.setFieldsValue('administrativeRegion',document.getElementById('district').value??null);   // 街道
                        this.forms2.setFieldsValue('street',document.getElementById('township').value??null);   // 街道
                        this.forms3.setFieldsValue('address',document.getElementById('latNum').value??null);    // 详情地址
                        this.forms4.setFieldsValue('latitude',document.getElementById('lat').value??null);
                        this.forms4.setFieldsValue('longitude',document.getElementById('lng').value??null);
                        this.setState({visible_3:false});
                    }}           	// 点击确定
                    onCancel={() =>{
                        this.setState({visible_3:false});
                    }}	// 点击取消
                    width={1400}
                    centered
                >
                    <div style={{width:'100%',height:'45px'}}>
                        <div style={{float:'left',width:'12%'}}>
                            <span style={{fontSize:'15px'}}>区：</span>
                            <input disabled={true} id={"district"} type={'text'}
                                   style={{border:"#d9d9d9 1px solid",width:'115px',height:'30px'}}
                            />
                        </div>
                        <div style={{float:'left',width:'13%'}}>
                            <span style={{fontSize:'15px'}}>街道：</span>
                            <input disabled={true} id={"township"} type={'text'}
                                   style={{border:"#d9d9d9 1px solid",width:'115px',height:'30px'}}
                            />
                        </div>
                        <div style={{float:'left',width:'40%'}}>
                            <span style={{fontSize:'15px'}}>详细地址：</span>
                            <input disabled={true} id={"latNum"} type={'text'}
                                style={{border:"#d9d9d9 1px solid",width:'85%',height:'30px'}}
                            />
                        </div>
                        <div style={{float:'left',width:'12%'}}>
                            <span style={{fontSize:'15px'}}>纬度：</span>
                            <input disabled={true} id={"lat"} type={'text'}
                                style={{border:"#d9d9d9 1px solid",width:'115px',height:'30px'}}
                            />
                        </div>
                        <div style={{float:'left',width:'12%'}}>
                            <span style={{fontSize:'15px'}}>经度：</span>
                            <input disabled={true} id={"lng"} type={'text'}
                                style={{border:"#d9d9d9 1px solid",width:'115px',height:'30px'}}
                            />
                        </div>
                    </div>
                    <div style={{width:'100%',height:'550px'}}>
                        <Map
                            ref={e => this.maps = e}
                            zoom={12}// 初始画面层级this.state.showMap 15
                            center={this.state.showMap}
                            onClick={async (a,b,)=>{
                                AMap.plugin("AMap.Geocoder", () => {
                                    var geoCoder = new AMap.Geocoder({
                                        city: "010", //城市设为北京，默认：“全国”
                                        radius: 1000, //范围，默认：500,
                                        extensions: 'all'
                                    });
                                    geoCoder.getAddress(new AMap.LngLat(
                                        a.lnglat.lng,
                                        a.lnglat.lat
                                    ), function(status, result) {
                                        if (status === 'complete'&&result.regeocode) {
                                            var district = result.regeocode.addressComponent.district??null;
                                            // if(district){
                                            //     if(!form_1.parameter[6].data.find(one=>{if(one.name===district)return true;})){
                                            //         return message.info('当前区域不可选');
                                            //     }
                                            // }
                                            document.getElementById('latNum').value = result.regeocode?.formattedAddress;
                                            document.getElementById('township').value = result.regeocode?.addressComponent.township??null;
                                            document.getElementById('district').value = result.regeocode?.addressComponent.district??null;
                                        }else{console.error('根据经纬度查询地址失败');}
                                    });
                                });
                                //----------------------------------------------------
                                document.getElementById('lat').value = a?.lnglat?.lat;//维度
                                document.getElementById('lng').value = a?.lnglat?.lng;//经度
                                this.setState({longitude:a?.lnglat?.lng, latitude:a?.lnglat?.lat})
                            }}
                        >
                            <Marker
                                title={'标点'}
                                position={new AMap.LngLat(
                                    this.state.longitude,
                                    this.state.latitude
                                )}
                                label={{
                                    content: '标点',
                                    direction: 'bottom' // 设置文本标注方位
                                }}

                            />
                        </Map>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
