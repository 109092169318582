import React, { Component  } from 'react';

import {Input, Form, Button, Col, Row, Select, DatePicker, message, InputNumber, AutoComplete, TreeSelect} from 'antd';
import moment from 'moment'

import {FORM_ITEM,RULE} from '../../Const'

const { RangePicker} = DatePicker;
const FormItem = Form.Item;
const { Option } = Select;

class FormsTwo extends Component {

    getData = (text) => {
        return this.form.getFieldValue(text);
    };

    setFieldsValue=(name,value)=>{
        if(!name)return false;
        this.form.setFieldsValue({
            [name.toString()]:value?value.toString():null
        })
    };
    // 会将对象解出对象名，按顺序排列赋值
    setFieldsValues=(rowData={})=>{
        if(!rowData)return false;
        var rowDataType = Object.keys(rowData);
        if(rowDataType.length===0)return false;
        for(var i=0;i < rowDataType.length;i++){
            if(rowData[rowDataType[i]]){
                this.setFieldsValue(rowDataType[i],rowData[rowDataType[i]]);
            }
        }
    };

    // 获取表单数据，清空数据
    onFinish = async () => {
        try {
            let value = await this.form.validateFields();
            this.form.resetFields();
            return value
        } catch (error) {
            return null
        }
    };

    // 获取表单数据，不清空数据
    onFinishNotReset = async () => {
        try {
            let value = await this.form.validateFields();
            return value;
        } catch (error) {
            return null;
        }
    };
    onFinishFailed = errorInfo => message.error('填写未完成或格式不正确'+errorInfo);

    // 清空表单数据
    onModalClose = async () =>{
        await this.form.resetFields()
    };
    CloseFieldsValues=(params)=>{
        for(var i=0;i < params.length;i++){
            if(params[i]?.variableName){
                if(params[i]?.rules.indexOf('select')!==-1){
                    this.setFieldsValue(params[i]?.variableName,[]);
                }else{
                    this.setFieldsValue(params[i]?.variableName,null);
                }

            }
        }
    };


    // 根据类型返回相应的item（可以考虑直接从父组件使用对象形式传来，那么这段代码就可以省略）
    // 时间组件宽度失效，所以手动设置值为100%
    getItem = (variableName,type,str,data,itemSize,isDisabled,onChange,isMultiple,fieldNames,addonAfter,onClick,numLength,min,max,maxLength) => {

        let place = isDisabled ? '系统自动带出' :`输入${str}`;
        switch (type) {
            case FORM_ITEM.PASSWORD:
                return <Input.Password disabled={isDisabled} placeholder={place}/>
            case FORM_ITEM.INPUT:
                return <Input disabled={isDisabled} placeholder={place} onChange={(e,v) => onChange?.(e,v)}  size={itemSize}/>
            case FORM_ITEM.INPUT_NUMBER:
                return <InputNumber disabled={isDisabled}
                                    style={{width: '100%'}}
                                    placeholder={place}
                                    size={itemSize}
                                    addonAfter={addonAfter}
                                    min={min}
                                    max={max}
                                    onChange={(e,v) => onChange?.(e,v)}
                />;
            case FORM_ITEM.REQ_NUMBER:
                return <InputNumber disabled={isDisabled}
                                    style={{width: '100%'}}
                                    placeholder={place}
                                    size={itemSize}
                                    addonAfter={addonAfter}
                                    min={0}
                                    max={max}
                                    onChange={(e,v) => onChange?.(e,v)}
                />;
            case FORM_ITEM.RANGE_PICKER:
                return <RangePicker disabled={isDisabled}
                                    style={{width: '100%'}} placeholder={['开始时间','结束时间']} size={itemSize}/>;
           case FORM_ITEM.RANGE_REQ_DATE_PICKER:
                return <RangePicker  disabled={isDisabled}
                                     style={{width: '100%'}} placeholder={['开始时间','结束时间']} size={itemSize}/>;
            case FORM_ITEM.RANGE_PICKER_TIME:
                return <RangePicker disabled={isDisabled}
                                    showTime={{ format: 'HH:mm:ss' }}
                                    format={"YYYY-MM-DD HH:mm:ss"}
                                    style={{width: '100%'}}
                                    placeholder={['开始时间','结束时间']}
                                    size={itemSize}/>;
            case FORM_ITEM.DATE_PICKER:
                return <DatePicker  disabled={isDisabled} style={{width: '100%'}} placeholder={'选择时间'} size={itemSize}/>;
            case FORM_ITEM.DATE_PICKER1:
                return <DatePicker showTime disabled={isDisabled} style={{width: '100%'}} placeholder={'选择时间'} size={itemSize}/>;

            case FORM_ITEM.DATE_PICKER2:
                return <DatePicker showTime={{ format: 'HH:mm' }} disabled={isDisabled} style={{width: '100%'}} placeholder={'选择时间'} size={itemSize}/>;

            case FORM_ITEM.DATE_PICKERTIME:
                return <DatePicker disabled={isDisabled} showTime style={{width: '100%'}} placeholder={'选择时间'} size={itemSize}/>;
            case FORM_ITEM.TEXT_AREA:
                return <Input.TextArea disabled={isDisabled}
                                       style={{height: maxLength,verticalAlign: 'middle'}}
                                       placeholder={str} size={itemSize}
                                       // showCount
                />;
            case FORM_ITEM.SELECT:
                return  <Select  mode={isMultiple ? "multiple" : null} disabled={isDisabled} placeholder={`选择${str}`} onChange={(e,v) => onChange?.(e,v)} size={itemSize}>;
                            {
                                data?.length > 0 ? data.map(option => {
                                    return <Option key={option.key} value={option.key}>{option.value}</Option>;
                                }) : null
                            }
                        </Select>;
            case FORM_ITEM.SELECTINPUT:
                return  <Select  mode={isMultiple ? "multiple" : null} disabled={isDisabled} placeholder={`选择${str}`} onChange={(e,v) => onChange?.(e,v)} size={itemSize}
                        optionFilterProp="children"
                        showSearch
                        // filterOption={(input, option) =>option.children.indexOf(input) >= 0}
                        >
                            {
                                data?.length > 0 ? data.map(option => {
                                    return <Option key={option.key} value={option.key}>{option.value}</Option>
                                }) : null
                            }
                        </Select>;
            case FORM_ITEM.AutoComplete:
                return  <AutoComplete disabled={isDisabled} placeholder={`选择${str}`} size={itemSize}
                                      onChange={(e,v) => onChange?.(e,v)}

                                      filterOption={(inputValue, option) => {
                                          return option?.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                                      }}
                >
                            {
                                data?.length > 0 ? data.map(option => {
                                    return <Option key={option.key} value={option.value}>{option.value}</Option>
                                }) : null
                            }
                        </AutoComplete>;
            case FORM_ITEM.INPUT_NUMBER_STR:
            return <InputNumber onChange={(e,v) => onChange?.(e,v)}
                                stringMode step="0.001" disabled={isDisabled} style={{width: '100%'}} placeholder={place} size={itemSize}/>;
            case FORM_ITEM.TEMPERATURE:
                return <InputNumber
                    stringMode
                    min="-50"
                    // max="80"
                    step={numLength===1?'0.1':numLength===2?'0.01':'0.001'}
                    disabled={isDisabled} style={{width: '100%'}}
                    placeholder={place}
                    size={itemSize}
                    addonAfter={addonAfter}
                    parser={(value: string | number): string => {
                        const reg1 = /^(\-)*(\d+)\.(\d).*$/;
                        const reg2 = /^(\-)*(\d+)\.(\d\d).*$/;
                        const reg3 = /^(\-)*(\d+)\.(\d\d\d).*$/;
                        if(typeof value === 'string') {
                            return !isNaN(Number(value)) ? value.replace(numLength===1?reg1:numLength===2?reg2:reg3, '$1$2.$3') : ''
                        } else if (typeof value === 'number') {
                            return !isNaN(value) ? String(value).replace(numLength===1?reg1:numLength===2?reg2:reg3, '$1$2.$3') : ''
                        } else {
                            return ''
                        }
                    }}
                />;
            case FORM_ITEM.PERCENTAGE:
                    return <InputNumber
                        onChange={(e,v) => onChange?.(e,v)}
                        stringMode
                        min={min}
                        max={max}
                        step={numLength===1?'0.1':numLength===2?'0.01':'0.001'}// 数组小数点长度
                        disabled={isDisabled}
                        style={{width: '100%'}}
                        placeholder={place}
                        size={itemSize}
                        addonAfter={addonAfter}
                        parser={(value: string | number): string => {
                            const reg1 = /^(\-)*(\d+)\.(\d).*$/;
                            const reg2 = /^(\-)*(\d+)\.(\d\d).*$/;
                            const reg3 = /^(\-)*(\d+)\.(\d\d\d).*$/;
                            if(typeof value === 'string') {
                                return !isNaN(Number(value)) ? value.replace(numLength===1?reg1:numLength===2?reg2:reg3, '$1$2.$3') : ''
                            } else if (typeof value === 'number') {
                                return !isNaN(value) ? String(value).replace(numLength===1?reg1:numLength===2?reg2:reg3, '$1$2.$3') : ''
                            } else {
                                return ''
                            }
                        }}


                    />;
            case FORM_ITEM.TREE_SELECT:
                return <TreeSelect onChange={(e,v) => onChange?.(e,v)}
                                   showSearch
                                   style={{ width: '100%' }}
                                   dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                   placeholder={place}
                                   allowClear
                                   fieldNames={fieldNames}
                                   treeNodeFilterProp={fieldNames?.label}
                                   treeData={data}
                />;
            case FORM_ITEM.REQ_TREE_SELECT:
                return <TreeSelect onChange={(e,v) => onChange?.(e,v)}
                                   showSearch
                                   style={{ width: '100%' }}
                                   dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                   placeholder={place}
                                   allowClear
                                   fieldNames={fieldNames}
                                   treeNodeFilterProp={fieldNames?.label}
                                   treeData={data}
                />;
            default:
                return <Input disabled={isDisabled} placeholder={`输入${str}`} size={itemSize}/>
        }
    };
    // 设置表单中的数据设置表单中的数据ID_NUMBER_STR
    setFormItem = parameter => {
        let data = this.props.data ?? {};
        let {number=2,itemSize} = this.props.formParamenter;
        let rowItem = [];
        // 列的默认长度
        let span = 24 / number;
        // 为下方循环的变量提前定义
        for (let i = 0,len = parameter.length; i < len; i++) {

            let arr = [];
            for(let j = 0; j < number; j++){
                arr.push(j)
            }

            // 将要显示的数据放入数组中，该数组直接返回，用于展示元素。
            // row：行，col：列，基本为一行两列，如果为文本域，则独占一行
            rowItem.push(
                <Row gutter={24} key={--i}>
                    {arr.map(v => {
                        return i < len ?
                            <Col span={span} key={++i}>
                                <FormItem
                                    name={parameter[i]?.variableName}
                                    label={parameter[i]?.str}
                                    rules={RULE[parameter[i]?.rules]?.rules}
                                    initialValue = {'datePicker' === parameter[i].rules ? data[parameter[i].variableName] ? moment(new Date(data[parameter[i].variableName])) : null : data[parameter[i].variableName]}
                                >
                                    {this.getItem(
                                        parameter[i]?.variableName,
                                        RULE[parameter[i].rules]?.type,
                                        parameter[i].str,
                                        parameter[i].data,
                                        itemSize,
                                        parameter[i].disabled,
                                        parameter[i].onChange,
                                        parameter[i].isMultiple,
                                        parameter[i].fieldNames,
                                        parameter[i].addonAfter,
                                        null,
                                        parameter[i].numLength,
                                        parameter[i].min,
                                        parameter[i].max,
                                        parameter[i].maxLength,
                                        )}
                                </FormItem>
                            </Col>
                        : null
                    })}
                </Row>
            )
        }
        return rowItem
    };

    render() {
// horizontal vertical
        const {parameter,width,itemSize,layout,modal=true,labelCol} = this.props.formParamenter ?? {};
        return (
            <div
                style={ modal ? null :{
                    width: width ? width+'vw' : '30vw',
                    padding: '0.5vh',
                    margin: '0.5vh'
                }}
            >
                <Form
                    layout={layout ?? 'horizontal'}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    labelCol={layout === 'vertical' ? null : { span: labelCol ?? 6}}
                    initialValues={this.props.data}
                    ref={e => this.form = e}
                    size={itemSize}
                >
                    {parameter ? this.setFormItem(parameter) : null}
                    {
                        modal ? null : (
                            <div style={{textAlign: 'center' }}>
                                <Button onClick={this.onModalClose} style={{ marginRight: 8 }}>
                                    清空
                                </Button>
                                <Button key='submit' type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </div>
                        )
                    }
                </Form>
            </div>
        )
    }
}

export default FormsTwo;
