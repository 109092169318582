// 证书管理
import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import moment from 'moment'
import './certificateManagement.less'
import $ from 'jquery'
import {funTraceInfo} from '../../../services/common'

import {getCMEntrustList,getCertificateInfoByEntrustId,certificateApproval} from '../../../services/verifvication'
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
var enId='';
export default class PointManagement extends Component {

    state = {
		// 委托单表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				entrustNum:'委托单号',
				entrustUnit: '委托单位',
				state: '状态',
				createTime: '委托时间'
			},
			// 弹出框
			headers: {
				entrustNum:'委托单号',
				entrustUnit: '委托单位',
				state: '状态',
				createTime: '委托时间'
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},
		ApprovalParamenter: {
			modal: true,
			number: 1,
			labelCol: 5,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'entrustUnit',
					str: '送检单位',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'auditStatus',
					str: '审核结果',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: 1,
							value: '通过'
						},
						{
							key: 2,
							value: '不通过'
						}
					]
				},
			]
		},
		// 证书表格参数
		tableParamenterTwo: {
					manageName: "数据项管理",
					// 表格行是否可选
					rowSelection: false,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						// lnstrumentName: '设备名',
						// model: '型号',
						startNumber:'设备编号',
						verifierTime: '提交时间',
						auditStatus: '审批状态',
						approvedByHandwriting: '审批员',
						edit: '操作',

					},
					// 弹出框
					headers: {
						// lnstrumentName: '设备名',
						// model: '型号',
						startNumber:'设备编号',
						verifierTime: '提交时间',
						auditStatus: '审批状态',
						approvedByHandwriting: '审批员',
					},
					params: {
						entrustId:enId,	// 委托单Id
						page:1,
						size:10,
					},
					// 是否绑定左侧区域
					bindArea: true
				},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'entrustNum',
					str: '委托单号'
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'entrustUnit',
					str: '委托单位'
				},

			]
		},
		    	// 总条数
		totalCount: 0,
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
		ApprovalVisible:false, //审批窗口开关器-1
		ApprovalId:'',
		ApprovalStatus:'',
		buttonJuder_1:'none'
	};
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'state':
				return text === '待接单' ? <span style={{color:'#E59722'}}>待接单</span> : text === '检定中' ? <span style={{color:'#DF6A5B'}}>检定中</span> : text === '检定完成' ? <span style={{color:'#44A072'}}>检定完成</span> : text === '作废' ? <span style={{color:'#999999'}}>作废</span>: '-';


			case 'edit':
				text=rowData.auditStatus;
				return <>
					{text==='未通过'?
						<div className={'tableNoLink'} title={'下载'}>
							<u>{'下载'}</u>
						</div>
						:
						<div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData?.certificateId)}>
							<u>{'下载'}</u>
						</div>
					}
					{text === '未通过' ?
						<div className={'tableNoLink'} title={'预览'}>
							<u>{'预览'}</u>
						</div>
						:
						<div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData?.certificateId)}>
							<u>{'预览'}</u>
						</div>
					}

				{text==='待审批' ?
					<div className={'tableLink'}
						 title={'审批'}
						 onClick={() => this.ApprovalStatus(true,rowData?.certificateId)}
						 style={{display: this.state.buttonJuder_1}}
					>
						<u>{'审批'}</u>
					</div>
				:
					<div className={'tableNoLink'} title={'审批'} style={{display: this.state.buttonJuder_1}}>
						<u>{'审批'}</u>
					</div>}
					</>;
			case 'createTime':
				text=moment(text).format("YYYY-MM-DD HH:mm:ss");
				return (<span>{text}</span>)
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	updatePoint = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		};
		this.props.history.push({
			pathname: '/device/addDataItem',
			state: data
		})
	};
	componentDidMount(){
		console.log(this.state.params)
		var menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
		console.log(menuAuthories);
		menuAuthories.map(one=>{
			if(one.name==='报警器检定管理'){
				one.children.map(two=>{
					if(two.name==='删除设备'){
						this.setState({deleteDeviceDisplay:''})
					}else if(two.name==='核验按钮'){
						this.setState({buttonJuder_1: ''})
					}
				});
			}
		});
	}
	onChangeSelect = e => {
		console.log(111,e)
	};

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		let formParams=this.tableOne.getParams()
		Object.assign(params,value)
		params.page=formParams.page
		params.size=formParams.size
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.tableOne.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.tableOne.refreshTable();
  }

  onRowId = async row => {
	console.log("选择委托单-获取证书");
	let params = this.state.tableParamenterTwo.params;
	params.entrustId=row.id;
	let {tableParamenterTwo} = this.state;
	tableParamenterTwo.params = params;
	this.setState({tableParamenterTwo});
	await this.tableTwo.refreshTable(params);
  }
	// 选择委托单-获取证书
  getSelectChange = row => {
    	console.log("选择委托单-获取证书");
    	console.log(row[0].id);
		enId=row[0].id;
		console.log(enId);
	  	let params = this.state.tableParamenterTwo.params;
		params.page=1;
		params.size=10;
	  	params.entrustId=row[0].id;
	  	params.entrustUnit=row[0].entrustUnit;
	  	let {tableParamenterTwo} = this.state;
	  	tableParamenterTwo.params = params;
	  	this.setState({tableParamenterTwo});
	    this.tableTwo.refreshTable(params);
	  // this.table.refreshTable();// 刷新表格

  };


  //审核
	ApprovalStatus= async (ApprovalStatus,id)=>{
		console.log('this.state.tableParamenter.params');
		console.log(this.state.tableParamenterTwo.params);
		var data={
			entrustId:this.state.tableParamenterTwo.params?.entrustId,
			entrustUnit:this.state.tableParamenterTwo.params?.entrustUnit
		};
		await this.setState({ApprovalStatus:ApprovalStatus,ApprovalId:id,
			data:data
		});
		await this.forms?.onModalClose()
		// this.forms.setFieldsValue("entrustUnit",this.state.tableParamenterTwo?.params?.entrustUnit);
	};
	changeApprovalStatus = async ()=>{
		let Approvaldata = await this.forms.onFinishNotReset();
		console.log(Approvaldata);
		if(!Approvaldata){return false}
		console.log('Approvaldata');
		let user = JSON.parse(sessionStorage.getItem("user"));

		// 审批人员
		// Approvaldata.approvedById=user.id;
		// Approvaldata.approvedByHandwriting=user.realName;

		// Approvaldata.approvedById=124;
		// Approvaldata.approvedByHandwriting='桑晓鸣';
		Approvaldata.approvedById=112;
		Approvaldata.approvedByHandwriting='盛浩';
		console.log(this.state.ApprovalId);
		if(this.state.ApprovalId){
			Approvaldata.id=this.state.ApprovalId;

		}
		this.setState({ApprovalStatus:false});
		$('.loadBox').show();
		Approvaldata.entrustId=this.state.tableParamenterTwo.params?.entrustId;
		console.log(Approvaldata);
		certificateApproval(Approvaldata).then(data=>{
			let params = this.state.tableParamenterTwo.params;
			let {tableParamenterTwo} = this.state;
			tableParamenterTwo.params = params;
			if(data.data?.data){
				$('.loadBox').hide();
				funTraceInfo("报警器检定管理", "审核", null,Approvaldata)
				message.success("审核成功");
				this.setState({ApprovalStatus:false});
			}else{
				$('.loadBox').hide();
				message.error('操作失败'+data.data?.responseInfo?.description);
				this.setState({ApprovalStatus:false});
			}
			this.tableTwo.refreshTable(params);// 刷新表格
		});


	};

	//下载窗口
	downloadStatus=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus,downloadId:id});
	};
	download=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus})
		console.log(this.state.downloadId)
	};

	downloadOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadNoBlankAndStampByCertificateId?id='+alarmId)
	};
	downloadOpen_1=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadNoBlankByCertificateId?id='+alarmId)
	};
	downloadOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadBlankByCertificateId')
	};

	//预览窗口
	previewStatus=(previewStatus,id)=>{
		console.log(id);
		this.setState({previewVisible:previewStatus,previewId:id});
	};
	preview=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus})
		console.log(this.state.previewId)
	};

	previewOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewNoBlankAndStampByCertificateId?id='+alarmId)
	};
	previewOpen_1=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewNoBlankByCertificateId?id='+alarmId)
	};
	previewOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewBlankByCertificateId')
	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params});
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables twoTables'}>
				<div className='orderTableBox'>
					<div className={'topSearchOrderBox br'}>
					<h3>委托单</h3>
					<Conditions
							conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
							getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
							clearCondition={this.clearCondition} // 清除条件
						>
					</Conditions>
					</div>
					<div className={'topSearchOrderBox'}>
						<h3>检定仪器</h3>

					</div>
					{/*委托单*/}
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getCMEntrustList}
						// setTableValue={this.setTableValue}
						// onClick={this.selectRow}
						getSelectChange={this.getSelectChange}	//选择事件
						setTableValue={this.setTableValue}
						ref={e => this.tableOne = e}

					>
					</TableComponents>
					{/*证书*/}
					<TableComponents
						paramenter={this.state.tableParamenterTwo}
						setData = {getCertificateInfoByEntrustId}
						setTableValue={this.setTableValue}
						ref={e => this.tableTwo = e}
					>
					</TableComponents>
					<Modal
						maskClosable={false}
                        title='下载'
                        style={{ top: 0 }}
                        visible={this.state.downloadVisible}
                        okText="确定"
                        onOk={() => this.downloadStatus(false)}           // 点击确定
                        onCancel={() => this.downloadStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.downloadOpenNull(this.state.downloadId,1,4,1)}>模板</span>
							<span onClick={() => this.downloadOpen(this.state.downloadId,0,4,0)}>有章证书</span>
							<span onClick={() => this.downloadOpen_1(this.state.downloadId,1,4,1)}>无章证书</span>
                        </div>
                    </Modal>
					<Modal
						maskClosable={false}
                        title='预览'
                        style={{ top: 0 }}
                        visible={this.state.previewVisible}
                        okText="确定"
                        onOk={() => this.previewStatus(false)}           // 点击确定
                        onCancel={() => this.previewStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.previewOpenNull(this.state.previewId,1,4,1)}>模板</span>
							<span onClick={() => this.previewOpen(this.state.previewId,0,4,0)}>有章证书</span>
							<span onClick={() => this.previewOpen_1(this.state.previewId,1,4,1)}>无章证书</span>
                        </div>
                    </Modal>
					<Modal
                        title='审核'
                        style={{ top: 0 }}
                        visible={this.state.ApprovalStatus}
                        okText="确定"
                        onOk={() => this.changeApprovalStatus()}           // 点击确定
                        onCancel={() => this.setState({ApprovalStatus:false})}       // 点击取消
                        width={500}
                        centered
                    >
					<div>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ApprovalParamenter}
							data={this.state.data}
						/>
					</div>
                    </Modal>
				</div>

				<div className={'loadBox'}>
					<div className={'loadInner'}></div>
					<div className={'loadIcon'}>
						<Spin></Spin>
						<p>加载中...</p>
					</div>
				</div>

            </Layout>
        )
    }
}
