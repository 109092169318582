import React, { Component } from 'react'
import {Button, Layout, message, Modal, Popconfirm, Select, Spin} from "antd";
import Conditions from "../../../components/common/conditions/Conditions";
import {TYPE_NAME} from "../../../components/Const";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {
    AlarmRecordInInvalid,
    allDelTemporaryAlarm,
    certificateApproval,
    getCertificateInfo,
    getEntrustList, removeAlarm
} from "../../../services/verifvication";
import {NavLink} from "react-router-dom";
import {PlusSquareOutlined} from '@ant-design/icons';
import FormsTwo from "../../../components/common/forms/FormsTwo";
import $ from "jquery";
import {funTraceInfo} from "../../../services/common";
import moment from "moment";
var downIp =global.pubData.url;
const { Option } = Select;
export default class  verificationMultifunctional extends Component {
    state={
        //弹窗-审批
        ApprovalParamenter: {
            modal: true,
            number: 1,
            labelCol: 5,
            layout: "horizontal",
            // itemSize: 'large',
            parameter: [
                {
                    variableName: 'entrustUnit',
                    str: '送检单位',
                    rules: TYPE_NAME.NOT_BLANK,

                },
                {
                    variableName: 'auditStatus',
                    str: '审核结果',
                    rules: TYPE_NAME.REQ_SELECT,
                    data: [
                        {
                            key: 1,
                            value: '通过'
                        },
                        {
                            key: 2,
                            value: '不通过'
                        }
                    ]
                },
            ]
        },

        // 表格参数
        tableParamenter: {
            overflow:true,
            manageName: "数据项管理",
            // 表格行是否可选
            // rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                entrustNum: '委托单号',
                entrustUnit: '委托单位',
                startNumber:'出厂编号',
                equipmentName: '设备名称',
                model: '型号规格',
                state: '委托单状态',
                alarmState: '进度状态',
                verifierDate: '检定时间',
                creator:'录入人',
                salesman:'业务员',
                verifier:'检定员',
                edit_1:'功能操作',
                edit_2: '文档操作',

            },

            params: {
                page:this.props?.location?.state?.page || 1,
                size:10,
                createTimeStart:'',
                createTimeEnd:''
            },
            // 是否绑定左侧区域
            bindArea: true
        },
        fromParamenter: {
            number: 2,
            labelCol: 12,
            parameter: [
                {
                    type: TYPE_NAME.SELECT_MULTIPLE,
                    variableName: 'verifierDates',
                    str: '业务员',
                    number:3,// 限定数量
                    data:[
                        {key: '陈佳伟', value: '陈佳伟'},
                        {key: '杨超', value: '杨超'},
                        {key: '马琼', value: '马琼'},
                        {key: '聂立功', value: '聂立功'},
                        {key: '曾思红', value: '曾思红'},
                        {key: '田辉', value: '田辉'},
                        {key: '杨能', value: '杨能'},
                        {key: '程立国', value: '程立国'},
                        {key: '盛浩', value: '盛浩'},
                        {key: '江军雄', value: '江军雄'},
                        {key: '李进', value: '李进'},
                    ],
                },

                {
                    type: TYPE_NAME.SELECT,
                    variableName: 'salesman',
                    str: '业务员',
                    data:[
                        {key: '韦杰', value: '韦杰'},
                        {key: '林迪启', value: '林迪启'},
                        {key: '蒋宁湘', value: '蒋宁湘'},
                        {key: '熊攻科', value: '熊攻科'},
                        {key: '杨能', value: '杨能'},
                        {key: '程立国', value: '程立国'},
                        {key: '盛浩', value: '盛浩'},
                        {key: '李梦婷', value: '李梦婷'},
                        {key: '陈佳伟', value: '陈佳伟'},
                        {key: '李进', value: '李进'},
                        {key: '江军雄', value: '江军雄'},
                        {key: '田辉', value: '田辉'},
                        {key: '曾思红', value: '曾思红'},
                        {key: '聂立功', value: '聂立功'},
                        {key: '马琼', value: '马琼'},
                        {key: '杨超', value: '杨超'},
                        {key: '其他', value: '其他'}
                    ]
                },
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'entrustNum',
                    str: '委托单号',
                },
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'entrustUnit',
                    str: '委托单位',
                },
                // {
                //     type: TYPE_NAME.NAME,
                //     variableName: 'lnstrumentName',
                //     str: '设备名称',
                // },
                {
                    type: TYPE_NAME.SELECT,
                    variableName: 'state',
                    str: '进度状态',
                    data:[
                        {key: 1, value: '待检定'},
                        {key: 2, value: '待核验'},
                        {key: 3, value: '待审批'},
                        {key: 4, value: '审批通过'},
                        {key: 5, value: '审批失败'},
                        {key: 6, value: '作废'},
                        {key: 7, value: '核验驳回'}
                    ]
                }
            ]
        },
        deleteDeviceDisplay:'none',
        buttonJuder_1:'none',
        buttonJuder_2:'none',
    };


    componentDidMount() {
        console.log("页面初始化");
        console.log(this.props?.location);
        var menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
        console.log(menuAuthories);
        menuAuthories.map(one => {
            if (one.name === '报警器检定管理') {
                one.children.map(two => {
                    if (two.name === '删除设备') {
                        this.setState({deleteDeviceDisplay: ''})
                    }else if(two.name==='审批按钮'){
                        this.setState({buttonJuder_1: ''})
                    }else if(two.name==='核验按钮'){
                        this.setState({buttonJuder_2: ''})
                    }
                });
            }
        });

        menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
        menuAuthories.map(one=>{
            if(one.name==='报警器检定管理'){
                one?.children.map(two=>{

                })
            }
        });

    }


    // 审核窗口操作
    ApprovalStatus=async (ApprovalStatus,id,entrustUnit,entrustId)=>{
        console.log(ApprovalStatus,+","+id);
        await this.setState({ApprovalStatus:ApprovalStatus,ApprovalId:id,
            data:{"entrustUnit":entrustUnit},
            entrustId:entrustId});
        await this.forms?.onModalClose()
         // this.forms.setFieldsValue("entrustUnit",entrustUnit);
    };
    // 审批
    changeApprovalStatus = async ()=>{
        let Approvaldata = await this.forms.onFinishNotReset();
        if(!Approvaldata){
            return false;
        }
        let user = JSON.parse(sessionStorage.getItem("user"));
        // 审批人员
        // Approvaldata.approvedById=user.id;
        // Approvaldata.approvedByHandwriting=user.realName;
        // Approvaldata.approvedById=124;
        // Approvaldata.approvedByHandwriting='桑晓鸣';
        Approvaldata.approvedById=112;
        Approvaldata.approvedByHandwriting='盛浩';
        if(this.state.ApprovalId){
            Approvaldata.id=this.state.ApprovalId;

        }
        Approvaldata.entrustId=this.state?.entrustId;
        this.setState({ApprovalStatus:false});
        $('.loadBox').show();
        console.log(Approvaldata);
        certificateApproval(Approvaldata).then(data=>{

            if(data.data?.data){
                $('.loadBox').hide();
                funTraceInfo("报警器检定管理", "审核", null,Approvaldata)
                message.success("审核成功");
                this.setState({ApprovalStatus:false});
            }else{
                $('.loadBox').hide();
                message.error('操作失败');
                this.setState({ApprovalStatus:false});
            }
            this.table.refreshTable(this.state.tableParamenter.params);// 刷新表格
        });


    };

    //下载窗口
    downloadStatus=(downloadStatus,rowData)=>{
        this.setState({downloadVisible:downloadStatus,downloadData:rowData});
    };
    // 检定书模板
    downloadOpenNull_1=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadBlankByAlarmId')
    };
    downloadOpen_1=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadByAlarmId?id='+alarmId)
    };

    // 证书模板
    downloadOpenNull_2=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadBlankByCertificateId')
    };
    downloadOpen_2=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadNoBlankAndStampByCertificateId?id='+alarmId)
    };
    downloadOpen_3=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadNoBlankByCertificateId?id='+alarmId)
    };


    //预览窗口
    previewStatus=(previewStatus,rowData)=>{
        this.setState({previewVisible:previewStatus,previewData:rowData});
    };
    // 检定模板预览
    previewOpenNull_1=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewBlankByAlarmId?id='+alarmId)
    };
    // 检定预览
    previewOpen_1=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewByAlarmId?id='+alarmId)
    };
    // 证书模板预览
    previewOpenNull_2=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewBlankByCertificateId')
    };
    // 证书有章预览
    previewOpen_2=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewNoBlankAndStampByCertificateId?id='+alarmId)
    };
    // 证书无章预览
    previewOpen_3=(alarmId,isNotBlank,fileType,needStamp)=>{
        console.log(alarmId);
        window.open(downIp+'/verification/pdfdownload/previewNoBlankByCertificateId?id='+alarmId)
    };

    //作废检定窗口显示
    toVoidStatus=(voidVisible,id)=>{
        this.setState({voidVisible,voidId:id});
    };
    toVoid=(voidVisible)=>{
        console.log(this.state.voidId);
        AlarmRecordInInvalid({gasId: this.state.voidId}).then(
            response => {
                console.log(response);
                this.setState({voidVisible});
                message.success('操作成功');
                // this.forms.onModalClose();
                this.table.refreshTable();// 刷新表格
            }
        )
    };


    setTableValue=(value,text,rowData)=>{

        switch (value) {

            case 'verifierDate':
                text =text?moment(text).format('YYYY-MM-DD'):'-';
                return(
                    <span>{text}</span>
                );

            case 'verifier':
                const title = text?.substring(1,text?.length-1) || '-';
                return <>
                    <div className={'table_overflow'} title={title}>{title}</div>
                </>;

            case 'state':
                return(
                    text === 1 ? <span style={{color:'#E59722'}}>待接单</span>
                        : text === 2 ? <span style={{color:'#DF6A5B'}}>检定中</span>
                        : text === 3 ? <span style={{color:'#44A072'}}>检定完成</span>
                            : text === 4 ? <span style={{color:'#999999'}}>检定作废</span>
                                        :'-'
                );
            case 'alarmState':
                return(
                    text === '1' ? <span style={{color:'#E59722'}}>待检定</span>
                        : text === '2' ? <span style={{color:'#DF6A5B'}}>待核验</span>
                        : text === '3' ? <span style={{color:'#44A072'}}>待审批</span>
                            : text === '4' ? <span style={{color:'#999999'}}>审批通过</span>
                                : text === '5' ? <span style={{color:'#999999'}}>审批失败</span>
                                    :text === '6' ? <span style={{color:'#999999'}}>作废</span>
                                        :text === '7' ? <span style={{color:'#E59722'}}>核验驳回</span>
                                            :'-'
                );
            case 'edit_1':
                rowData.inspectionUnit=rowData.entrustUnit;

                return <>

                    {/*-----------------------------检定-------------------------------*/}
                    {(rowData.alarmState==='1' || rowData.alarmState==='7') & (rowData.state!==4 & rowData.state!==3)
                        ?
                        <NavLink to={{
                            pathname: "/verifvication/verificationRegistration",
                            state: {
                                pathname: '/verifvication/verificationMultifunctional',
                                page:this.state.tableParamenter.params.page,
                                id: rowData.alarmId,
                                rowVal:rowData,
                                alarmState:rowData.alarmState,
                                reason:rowData?.voRejects[rowData?.voRejects.length-1]?.reason || null
                            }}}
                        >
                            <div className={'tableLink'} title={'检定'}>
                                <u>{'检定'}</u>
                            </div>
                        </NavLink>
                        :
                        <div className={'tableNoLink'} title={'检定'}>
                            <u>{'检定'}</u>
                        </div>
                    }
                    {/*-----------------------------核验-------------------------------*/}
                    {(rowData.alarmState==='2' & rowData.state!==4 & rowData.state!==3)
                        ? <NavLink to={{
                            pathname: "/verifvication/verificationRegistration",
                            state: {
                                pathname: '/verifvication/verificationMultifunctional',
                                page:this.state.tableParamenter.params.page,
                                id: rowData.alarmId,
                                alarmState:rowData.alarmState,
                                // customerId:this.state.data.customerId,
                                formData:true,
                                status:2
                            }
                        }}>
                            <div className={'tableLink'} title={'核验'} style={{display: this.state.buttonJuder_2}}>
                                <u>{'核验'}</u>
                            </div>
                        </NavLink>
                        : <div className={'tableNoLink'} title={'核验'} style={{display: this.state.buttonJuder_2}}>
                            <u>{'核验'}</u>
                        </div>
                    }
                    {/*-----------------------------审批-------------------------------*/}
                    {(rowData.alarmState==='3' & rowData.state!==4 & rowData.state!==3)
                        ?<div className={'tableLink'}
                              title={'审批'}
                              onClick={() => this.ApprovalStatus(true,rowData.certificateId,rowData.entrustUnit,rowData.entrustId)}
                              style={{display: this.state.buttonJuder_1}}
                        >
                            <u>{'审批'}</u>
                        </div>
                        :<div className={'tableNoLink'}
                              title={'审批'}
                              style={{display: this.state.buttonJuder_1}}
                        >
                            <u>{'审批'}</u>
                        </div>
                    }
                    {/*-----------------------------作废-------------------------------*/}
                    {(rowData.alarmState==='6' || rowData.state===4 || rowData.state===3)
                        ?
                        <div className={'tableNoLink'}
                              title={'检定作废'}>
                            <u>{'检定作废'}</u>
                        </div>
                        :
                        <div className={'tableLink'}
                        title={'检定作废'}
                        onClick={() => this.toVoidStatus(true,rowData.alarmId)}
                        >
                        <u>{'检定作废'}</u>
                        </div>
                    }

                    <Popconfirm
                        title="是否确定删除"
                        description="是否确定删除?"
                        onConfirm={()=>{
                            removeAlarm({gasId:rowData.alarmId}).then(response=>{
                                if(response?.data?.data){
                                    message.info('删除成功')
                                }else{
                                    message.info('删除失败')
                                }
                                this.table.refreshTable(this.state.tableParamenter.params);
                            }).catch(e=>{message.info('异常问题，请重新操作')})
                        }}
                        // onCancel={cancel}
                        okText="是"
                        cancelText="否"
                    >
                        <div className={'tableLink'}
                             title={'删除设备'}
                             style={{display: this.state.deleteDeviceDisplay}}
                        >
                            <u>{'删除设备'}</u>
                        </div>
                    </Popconfirm>

                    {/*<div className={'tableLink'}*/}
                    {/*     title={'删除设备'}*/}
                    {/*     style={{display: this.state.deleteDeviceDisplay}}*/}
                    {/*     onClick={() =>{*/}

                    {/*         removeAlarm({gasId:rowData.alarmId}).then(response=>{*/}
                    {/*             if(response?.data?.data){*/}
                    {/*                 message.info('删除成功')*/}
                    {/*             }else{*/}
                    {/*                 message.info('删除失败')*/}
                    {/*             }*/}
                    {/*             this.table.refreshTable(this.state.tableParamenter.params);*/}
                    {/*         }).catch(e=>{message.info('异常问题，请重新操作')})*/}

                    {/*     }}*/}
                    {/*>*/}
                    {/*    <u>{'删除设备'}</u>*/}
                    {/*</div>*/}
                </>;
            case 'edit_2':
                return <>
                    <div className={'tableLink'} title={'下载'}
                         onClick={() => this.downloadStatus(true,rowData)}>
                        <u>{'下载'}</u>
                    </div>
                    <div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData)}>
                        <u>{'预览'}</u>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };


    // 条件选择：获取输入的条件
    getConditionsValue = async value => {
        let {tableParamenter} = this.state;
        tableParamenter.params.entrustNum=value?.entrustNum;
        tableParamenter.params.entrustUnit=value?.entrustUnit;
        tableParamenter.params.lnstrumentName=value?.lnstrumentName;
        tableParamenter.params.model=value?.model;
        tableParamenter.params.salesman=value?.salesman;
        tableParamenter.params.verifier=value?.verifierDates || null;
        tableParamenter.params.state=value.state || null;
        this.setState({tableParamenter});
        await this.table.refreshTable(tableParamenter.params);
    };
    // 清空条件
    clearCondition = () => {
        var params={
            page:1,
            size:10,
        };
        let {tableParamenter} = this.state;
        tableParamenter.params=params;
        this.setState({tableParamenter,verifierDates:[]});
        this.table.refreshTable(this.state.tableParamenter.params);
    };
    render(){
        return(
            <Layout className={'item_body_number_back tables'}>
                <div id={'verificationMultifunctional'}>
                    <div className={'topSearchBox'}>
                        <h3>检定多功能页面</h3>
                        <div style={{height:'70px'}}>
                            {/*<div style={{width:'25%',float:'left'}}>*/}
                            {/*    <div style={{width:'100%'}}>*/}
                            {/*        <div style={{width:'20%',float:'left',textAlign:'right'}}>*/}
                            {/*            <span style={{fontSize:'16px', paddingRight: '8px'}}>检定员:</span>*/}
                            {/*        </div>*/}
                            {/*        <div style={{width:'75%',float:'left'}}>*/}
                            {/*            <Select*/}
                            {/*                style={{width:'100%'}}*/}
                            {/*                mode="multiple"*/}
                            {/*                allowClear*/}
                            {/*                value={this.state.verifierDates}*/}
                            {/*                placeholder={'请输入检定员'}*/}
                            {/*                onChange={(title,data)=>{*/}
                            {/*                    if(title?.length>3){*/}
                            {/*                        return message.info('仅限三位');*/}
                            {/*                    }*/}
                            {/*                    this.setState({verifierDates:title});*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                <Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>*/}
                            {/*                <Option key={'杨超'} label={'杨超'}>杨超</Option>*/}
                            {/*                <Option key={'马琼'} label={'马琼'}>马琼</Option>*/}
                            {/*                <Option key={'聂立功'} label={'聂立功'}>聂立功</Option>*/}
                            {/*                <Option key={'曾思红'} label={'曾思红'}>曾思红</Option>*/}
                            {/*                <Option key={'田辉'} label={'田辉'}>田辉</Option>*/}
                            {/*                <Option key={'杨能'} label={'杨能'}>杨能</Option>*/}
                            {/*                <Option key={'程立国'} label={'程立国'}>程立国</Option>*/}
                            {/*                <Option key={'盛浩'} label={'盛浩'}>盛浩</Option>*/}
                            {/*                <Option key={'江军雄'} label={'江军雄'}>江军雄</Option>*/}
                            {/*                <Option key={'李进'} label={'李进'}>李进</Option>*/}
                            {/*            </Select>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div style={{width:'100%',float:'left'}}>
                                <Conditions
                                    ref={e => this.forms = e}
                                    conditionsParamenter={this.state.fromParamenter} // 条件框输入框设置
                                    getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                                    clearCondition={this.clearCondition} // 清除条件
                                >
                                </Conditions>
                            </div>
                        </div>
                    </div>
                    {/*----------刷新---------*/}
                    <div style={{width:'100%',height:'40px',padding:'1%',backgroundColor: '#fff'}}>
                        <div className={'tableLink'} style={{float:'right'}}>
                            <u  onClick={()=>{this.table.refreshTable(this.state.tableParamenter.params)}}>刷新</u>
                        </div>
                    </div>
                    {/*-----------表格-----------*/}
                    <div style={{width:'100%',height:'620px',overflowX: 'auto',background:'#fff'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            // setData = {getEntrustList}
                            setData = {getCertificateInfo}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        >
                        </TableComponents>
                    </div>
                    {/*<div style={{width:'100%',float:'left'}}>*/}
                    {/*    */}
                    {/*</div>*/}

                    <div className={'loadBox'}>
                        <div className={'loadInner'}></div>
                        <div className={'loadIcon'}>
                            <Spin></Spin>
                            <p>加载中...</p>
                        </div>
                    </div>
                </div>

                {/*--------------------审核---------------------*/}
                <Modal
                    title='审核'
                    style={{ top: 0 }}
                    visible={this.state.ApprovalStatus}
                    okText="确定"
                    onOk={() => this.changeApprovalStatus()}           // 点击确定
                    onCancel={() => this.setState({ApprovalStatus:false})}       // 点击取消
                    width={500}
                    centered
                >
                    <div>
                        <FormsTwo
                            ref={e => this.forms = e}
                            formParamenter={this.state.ApprovalParamenter}
                            data={this.state.data}
                        />
                    </div>
                </Modal>
                {/*--------------------下载---------------------*/}
                <Modal
                    maskClosable={false}
                    title='下载'
                    style={{ top: 0 }}
                    visible={this.state.downloadVisible}
                    okText="确定"
                    onOk={() => this.downloadStatus(false)}           // 点击确定
                    onCancel={() => this.downloadStatus(false)}       // 点击取消
                    width={600}
                    centered
                >
                    {/*检定书*/}
                    <div style={{padding:'0 10px',textAlign:'center',height:'50px'}}>
                        <Button disabled={this.state.downloadData?.alarmId?false:true}
                                type="primary" style={{margin:'0 10px'}}
                                onClick={() =>
                                    this.downloadOpenNull_1(
                                        this.state.downloadData?.alarmId,0,1,1)}>
                            检定记录模板</Button>
                        <Button disabled={this.state.downloadData?.alarmId?false:true}
                                type="primary" style={{margin:'0 10px'}}
                                onClick={() => this.downloadOpen_1(this.state.downloadData?.alarmId,0,1,0)}>检定记录记录</Button>
                    </div>
                    {/*证书*/}
                    <div style={{padding:'0 10px',textAlign:'center',height:'50px'}}>
                        <Button disabled={this.state.downloadData?.certificateId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.downloadOpenNull_2(this.state.downloadData?.certificateId,1,4,1)}>证书模板</Button>
                        <Button disabled={this.state.downloadData?.certificateId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.downloadOpen_2(this.state.downloadData?.certificateId,0,4,0)}>有章证书</Button>
                        <Button disabled={this.state.downloadData?.certificateId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.downloadOpen_3(this.state.downloadData?.certificateId,1,4,1)}>无章证书</Button>
                    </div>
                </Modal>
                {/*--------------------预览---------------------*/}
                <Modal
                    maskClosable={false}
                    title='预览'
                    style={{ top: 0 }}
                    visible={this.state.previewVisible}
                    okText="确定"
                    onOk={() => this.previewStatus(false)}           // 点击确定
                    onCancel={() => this.previewStatus(false)}       // 点击取消
                    width={600}
                    centered
                >

                    {/*检定书downloadData*/}
                    <div style={{padding:'0 10px',textAlign:'center',height:'50px'}}>
                        <Button disabled={this.state.previewData?.alarmId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.previewOpenNull_1(this.state.previewData?.alarmId,0,1,1)}>检定记录模板</Button>
                        <Button disabled={this.state.previewData?.alarmId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() =>{this.previewOpen_1(this.state.previewData?.alarmId,0,1,0)}}>检定记录记录</Button>
                    </div>
                    {/*证书previewData*/}

                    <div style={{padding:'0 10px',textAlign:'center',height:'50px'}}>
                        <Button disabled={this.state.previewData?.certificateId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.previewOpenNull_2(this.state.previewData?.certificateId,1,4,1)}>证书模板</Button>
                        <Button disabled={this.state.previewData?.certificateId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.previewOpen_2(this.state.previewData?.certificateId,0,4,0)}>有章证书</Button>
                        <Button disabled={this.state.previewData?.certificateId?false:true} type="primary" style={{margin:'0 10px'}} onClick={() => this.previewOpen_3(this.state.previewData?.certificateId,1,4,1)}>无章证书</Button>
                    </div>
                </Modal>
                {/*--------------------作废---------------------*/}
                <Modal
                    title='作废'
                    style={{ top: 0 }}
                    visible={this.state.voidVisible}
                    okText="确定"
                    onOk={() => this.toVoid(false)}           // 点击确定
                    onCancel={() => this.toVoidStatus(false)}       // 点击取消
                    width={400}
                    centered
                >
                    <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
                        <h4>确认作废该检定记录吗？</h4>
                    </div>
                </Modal>
            </Layout>
        );
    }
}
